import { FunctionComponent, useState } from "react"
import AuthLayout from "./authLayout";
import NavBarAdmin from "../components/template/navBar/navBarAdmin";
import SubMenuAdmin from "../components/template/subMenu/subMenuAdmin";
import AuthAdminTest from "../components/authAdmin/authAdminTest/authAdminTest";

const AdmissionTestPage: FunctionComponent= () => {
  const [loaderForm, setLoaderForm]= useState<boolean>(false);
  return <AuthLayout content={<AuthAdminTest setLoaderForm={setLoaderForm} />} navbar={<NavBarAdmin />} submenu={<SubMenuAdmin/>} loaderForm={loaderForm} />
} 
export default AdmissionTestPage