import { FunctionComponent, useState } from "react"
import AuthLayout from "./authLayout";
import NavBarAdmin from "../components/template/navBar/navBarAdmin";
import SubMenuAdmin from "../components/template/subMenu/subMenuAdmin";
import AuthAdminAdmission from "../components/authAdmin/authAdminAdmission/authAdminAdmission"



const AdmissionListPage: FunctionComponent= () => {
  const [loaderForm, setLoaderForm]= useState<boolean>(false);
  return <AuthLayout content={<AuthAdminAdmission setLoaderForm={setLoaderForm} />} navbar={<NavBarAdmin />} submenu={<SubMenuAdmin/>} loaderForm={loaderForm} />
} 
export default AdmissionListPage