import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import AuthHeader from "../components/authHeader/authHeader";
import AuthNavbar from "../components/authNavbar/authNavbar";
import AuthSubMenu from "../components/authSubmenu/authSubMenu";
import AuthContent from "../components/authContent/authContent";
import AuthFooter from "../components/authFooter/authFotter";
import MobileAuth from "../components/authMobile/authHeader";
import AuthBottom from "../components/authMobile/authBottom";
import AuthAdminBottom from "../components/authMobile/authAdminBottom";
import { isAuthentificated } from "../services/auth";
import "./authLayout.css"

interface Props {
  content: ReactNode;
  navbar: ReactNode;
  submenu: ReactNode;
  loaderForm: boolean
}

const AuthLayout: FunctionComponent<Props> = ({ navbar, submenu, content, loaderForm }) => {
  const [preloader, setPreloader] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const onPageLoad = () => {
      setPreloader(true);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  const title = document.URL.split("/")[3];
  function capitalizeFirstLetter(a: string) {
    return a.charAt(0).toUpperCase() + a.slice(1);
  }

  if (document.title !== "Codeloccol | 404") {
    if (title !== "") document.title = "Codeloccol | " + capitalizeFirstLetter(title);
    else document.title = "Codeloccol";
  }

  return (
    <>
      {
        isMobile ?
          <>
            <div className="container-fluid">
              <MobileAuth />
              <div className="pt">
                <AuthContent content={content} />
              </div>
              {
                isAuthentificated().profil === "admin" ? <AuthAdminBottom /> : <AuthBottom />
              }
              
            </div>
          </>
          :
          <>
            <div id="preloader" className={`${preloader && "d-none"}`}>
              <div data-loader="circle-side" className={`${preloader && "d-none"}`} />
            </div>
            <div id="loader_form" className={`${!loaderForm && "d-none"}`}>
              <div data-loader="circle-side" className={`${!loaderForm && "d-none"}`} />
            </div>
            <div className="container-fluid px-0">
              <AuthHeader />
              <div className="content">
                <AuthNavbar navbar={navbar} />
                <div className="section-content">
                  <div className="row all-content">
                    <div className="col-md-3 pe-0">
                      <AuthSubMenu submenu={submenu} />
                    </div>
                    <div className="col-md-9 px-0">
                      <AuthContent content={content} />
                    </div>
                  </div>
                </div>
              </div>
              <AuthFooter />
            </div>
          </>
      }
    </>
  )
}

export default AuthLayout