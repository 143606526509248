import { FunctionComponent } from "react";

interface Props {
  learn : LearnModel
}

const LearnModel: FunctionComponent<Props> = ({ learn })=> {
  return(
    <div className="sliderItem block slick-slide slick-cloned col-lg-4 col-sm-6 col-xs-12">
      <div className="content-model">
        <img src={learn.picture} alt="Learn Picture" className="img-fluid learn"/>
        <div className="slider-content text-center">
          <div className="title">
            <p>
              <strong>{learn.title}</strong>
            </p>
          </div>
          <p className="learn">
          {learn.message}
          </p>
        </div>
      </div>
    </div>
  )
}

export default LearnModel;