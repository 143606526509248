import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faUserGraduate } from "@fortawesome/free-solid-svg-icons";

const AuthAdminBottom = () => {
  const [value, setValue] = React.useState(0);
  const title = document.URL.split("/")[4]
  return (
    <Box sx={{position: 'fixed', bottom: 0, left:0, right:0, borderTop: "1px solid #179349", marginTop:10}}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <Link to={"/admission/list"} className={`${title === "list" && "Mui-selected"}`}>
          <BottomNavigationAction label="Info" icon={<FontAwesomeIcon icon={faUsers} />}/>
        </Link>
        <Link to={"/admission/test"} className={`${title === "test" && "Mui-selected"}`}>
          <BottomNavigationAction label="Test" icon={<FontAwesomeIcon icon={faUserGraduate} />}/>
        </Link>
      </BottomNavigation>
    </Box>
  );
}

export default AuthAdminBottom