import { FunctionComponent } from "react"
import Layout from "./layout"
import FaqBanner from "../components/banner/faq"
import FaqContent from "../components/faq/faq"

interface Props {
  counter : number
}

const FaqPage: FunctionComponent<Props> = ({ counter }) => {
  return <Layout content={<FaqContent />} banner={<FaqBanner />} loaderForm={false} counter={counter} />
}

export default FaqPage