import { FunctionComponent, useState, ChangeEvent, FormEvent } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faUser, faLock, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { ConnexionService } from "../../services/ConnexionServices";
import "./login.css";
import jwt_decode from 'jwt-decode';
import { isMobile } from 'react-device-detect';

interface Props {
	setLoaderForm: (loader: boolean) => void
}

const LoginContent: FunctionComponent<Props> = ({ setLoaderForm }) => {
	const [form, setForm] = useState<Login>({
		login: { value: "", isValid: true },
		password: { value: "", isValid: true }
	});
	const [errorMsg, setErrorMsg] = useState<string>("")

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const fieldName: string = e.target.name;
		const fieldValue: string = e.target.value;
		const newField: Field = {
			[fieldName]: { value: fieldValue, isValid: true },
		};
		setForm({ ...form, ...newField });
	};

	const validateForm = () => {
		let newForm: Login = form;

		if (!/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g.test(form.login.value)) {
			const errorMsg: string = `Vous devez saisir votre identifiant`;
			const newField: Field = {
				value: form.login.value,
				error: errorMsg,
				isValid: false,
			};
			newForm = { ...newForm, ...{ login: newField } };
		} else {
			const newField: Field = {
				value: form.login.value,
				error: "",
				isValid: true,
			};
			newForm = { ...newForm, ...{ login: newField } };
		}

		if (form.password.value.trim().length < 8) {
			const errorMsg: string = `Vous devez saisir votre mot de passe`;
			const newField: Field = {
				value: form.password.value,
				error: errorMsg,
				isValid: false,
			};
			newForm = { ...newForm, ...{ password: newField } };
		} else {
			const newField: Field = {
				value: form.password.value,
				error: "",
				isValid: true,
			};
			newForm = { ...newForm, ...{ password: newField } };
		}

		setForm(newForm);
		return (newForm.login.isValid && newForm.password.isValid);
	};

	const newObject = (object: any) => {
		let newObj: any = {};
		for (let obj in object) {
			if (obj !== "checkMachine") {
				if (obj !== "calcul") newObj[obj] = object[obj].value;
			}
		}
		return newObj;
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const validate = validateForm();
		if (validate) {
			setLoaderForm(true);
			const connexion = newObject(form);
			const connexionService = new ConnexionService();
			connexionService.assiri(connexion).then(res => {
				if (res !== "La combinaison identifiant/mot de passe est incorrecte" && res !== "Tous les champs sont requis") {
					const token = res.token;
					sessionStorage.setItem('__SECURE', token);
					if (token !== "{}" && token !== "") {
						const decoded: Token = jwt_decode(token);

						if (decoded.profil === "candidate") window.location.replace('/user/info');
						else window.location.replace('/admission/list');
					}
				}
				else {
					setErrorMsg("🔐 Identifiant ou mot de password...");
					const copy = form;
					copy.login.value = copy.password.value = "";
					setForm(copy)
					setLoaderForm(false);
				}
			}).catch(err => {
				setLoaderForm(false);
				console.log(err);
			}).finally(() => setLoaderForm(false))
		}
	};


	return (
		<>
			{
				isMobile ? (
					<>
						<div className="login__form">
							<div className="login__container">
								<div className="screen">
									<div className="screen__content">
										<div className="logo">
											<img src="https://lh3.googleusercontent.com/d/1BKvZNCkPaU618_ddXMUa7apUS9zZniZJ" alt="logo" />
										</div>
										{
											errorMsg !== "" && (<p className="error">{errorMsg}</p>)
										}
										<form onSubmit={e => { handleSubmit(e) }} className="login">
											<div className="login__field">
												<FontAwesomeIcon className="login__icon" icon={faUser} size={"lg"} />
												<input
													type="text"
													name="login"
													className="login__input"
													placeholder="Identifiant"
													value={form.login.value}
													onChange={e => { handleInputChange(e) }}
												/>
												{!form.login.isValid && (
													<>
														<p className="red-text">{form.login.error}</p>
													</>
												)}
											</div>
											<div className="login__field">
												<FontAwesomeIcon className="login__icon" icon={faLock} size={"lg"} />
												<input
													type="password"
													name="password"
													className="login__input"
													placeholder="Mot de passe"
													value={form.password.value}
													onChange={e => { handleInputChange(e) }}
												/>
												{!form.password.isValid && (
													<>
														<p className="red-text">{form.password.error}</p>
													</>
												)}
											</div>
											<button className="button login__submit">
												<span className="button__text">Se connecter</span>
												<FontAwesomeIcon className="button__icon" icon={faChevronRight} size={"lg"} />
											</button>
										</form>
										<div className="social-login"></div>
									</div>
									<div className="screen__background">
										<span className="screen__background__shape screen__background__shape4"></span>
										<span className="screen__background__shape screen__background__shape3"></span>
										<span className="screen__background__shape screen__background__shape2"></span>
										<span className="screen__background__shape screen__background__shape1"></span>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div id="form_login">
							<div className="row admission w-100 mx-0">
								<div className="col-lg-7 px-0">
									<div id="left_login"></div>
								</div>
								<div className="col-lg-5 px-0">
									<div id="rigth_login">
										<div>
											<figure className="d-flex justify-content-center">
												<Link to="/">
													<img src="https://lh3.googleusercontent.com/d/1BKvZNCkPaU618_ddXMUa7apUS9zZniZJ" alt="Logo Codeloccol" width="350" height="248" />
												</Link>
											</figure>
											<div className="">
												<form
													id="wrapped"
													className="fl-form fl-style-1 wizard-form"
													onSubmit={e => { handleSubmit(e) }}
												>
													<div className="row mx-4 justify-content-evenly">
														<div className="col col-sm-12 text-center">
															{
																errorMsg !== "" && (<p className="error">{errorMsg}</p>)
															}
														</div>
													</div>
													<h3 className="main_question">
														<FontAwesomeIcon icon={faArrowRight} />
													</h3>
													<div className="form-group add_top_30">
														<label>Identifiant</label>
														<input
															type="text"
															name="login"
															value={form.login.value}
															onChange={e => handleInputChange(e)}
															className="form-control required"
														/>
														{!form.login.isValid && (
															<>
																{form.login.value.trim() === "" ? (
																	<span className="error"> Obligatoire </span>
																) : (
																	<span className="error">
																		{form.login.error}
																	</span>
																)}
															</>
														)}
													</div>
													<div className="form-group">
														<label>Mot de passe</label>
														<input
															type="password"
															name="password"
															value={form.password.value}
															onChange={e => handleInputChange(e)}
															className="form-control required"
														/>
														{!form.password.isValid && (
															<>
																{form.password.value.trim() === "" ? (
																	<span className="error"> Obligatoire </span>
																) : (
																	<span className="error">
																		{form.password.error}
																	</span>
																)}
															</>
														)}
													</div>
													<div className="mt-2 d-grid gap-2">
														<button className="btn btn-primary" type="submit">Se connecter</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)
			}
		</>
	)
}

export default LoginContent;