import { FunctionComponent, useState } from "react";

interface Props {
  student : Students
}

const Student: FunctionComponent<Props> = ({ student})=> {
  const [flip, setFlip] = useState<boolean>(false);
  return (
    <div className={`col-lg-3 col-sm-6 col-xs-12 flipCardItem block ${flip ? "flipped" : ""}`} onClick={()=> {setFlip(!flip)}}>
      <div className="flipCardItem-inner">
        <div className="flipCardItem-front">
          <img src={student.picture} alt="Students" className="img-fluid" />
          <h2>{student.name} {student.age > 0 ? student.age : ""}</h2>
          <div>
            <p className="p1">{student.profil}</p>
          </div>
          <span />
        </div>
        <div className="flipCardItem-back">
          <p className="says">
            {student.says}
          </p>{" "}
          <span />
        </div>
      </div>
    </div>
  )
}

export default Student