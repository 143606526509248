import { FunctionComponent } from "react";
import "./banner.css"
const FaqBanner: FunctionComponent = () => {
  return (
    <section id="faq_banner" className="parallax_window_in">
      <div id="sub_content_in">
        <h1>Foire aux questions</h1>
      </div>
    </section>
  );
};

export default FaqBanner;
