import { FunctionComponent, useState, useEffect } from "react";
import "./authUserInfo.css";
import { isAuthentificated } from "../../../services/auth";
import { AdmissionService } from "../../../services/AdmissonServices";
interface Props {
  setLoaderForm: (loaderForm: boolean) => void;
}
const AuthUserInfo: FunctionComponent<Props> = ({ setLoaderForm }) => {
  const admissionService = new AdmissionService();
  const [see, setSee] = useState<boolean>(false);
  const [candidat, setCandidat] = useState<AdmissionApi>();
  useEffect(() => {
    setLoaderForm(true);
    admissionService.findAdmission().then(user => {
      if(user[0]) {
        setCandidat(user[0]);
        setSee(true);
      }
    }).catch(err => {
      console.log(err);
      setSee(true)
    }).finally(()=> setSee(true));
    setLoaderForm(false);
  }, []);
  return (
    <>
      {
        !see && (
          <div className="est">
            <span className="loader"></span>
          </div>
        )
      }
      <section className={`info ${!see && "d-none"}`}>
        <div className="container py-5 mx-0">
          <div className="row">
            <div className="main_title_2">
              <span>
                <em />
              </span>
              <h2>Informations personnelles</h2>
            </div>
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src="https://drive.google.com/uc?export=view&id=1J9A2xJsHcZkiV6_qgORQA8h4TlSF2-71"
                    alt="avatar"
                    className="rounded-circle img-fluid"
                  />
                  <h5 className="my-3">{candidat?.name}</h5>
                  <p className="text-muted mb-1 text-capitalize">{isAuthentificated().profil}</p>
                  <p className="text-muted mb-4">{candidat?.city} {candidat?.country}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Nom & Premon</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{candidat?.name}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{candidat?.mail}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">WhatsApp</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">+{`${candidat?.mobile.toString().startsWith("227227") ? candidat?.mobile.toString().replace("227227", "") : candidat?.mobile}`}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Pays</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{candidat?.country}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Ville</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{candidat?.city}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Age</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{candidat?.age} ans</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-evenly">
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <h5 className="my-3">Motivation</h5>
                  <hr />
                  <div className="p-100">
                    <p className="text-justify">{candidat?.coding}</p>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <h5 className="my-3">Aventure</h5>
                  <hr />
                  <div className="p-100">
                    <p className="text-justify">{`${candidat?.isLearnCoding ? candidat?.adventure : candidat?.whyDoNotLearnCoding}`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-evenly">
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <h5 className="my-3">Ton projet</h5>
                  <hr />
                  <div className="p-100">
                    <p className="text-justify">{candidat?.likeToAchieve}</p>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <h5 className="my-3">Environement Tech</h5>
                  <hr />
                  <div className="p-100">
                    <p className="text-justify">{candidat?.techEnvironment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default AuthUserInfo;