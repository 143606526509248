export class CountryService {
  
  public async getAllCountries(): Promise<any> {
    const response = await fetch("https://countriesnow.space/api/v0.1/countries/codes");
    const responseJson = await response.json();
    return responseJson;
  }

  public async getAllCitiesOfCountry(data: any): Promise<any> {
    const response = await fetch("https://countriesnow.space/api/v0.1/countries/cities", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ country: data.toLowerCase() }),
      });
    return await response.json();
  }
}