const url = "https://lh3.googleusercontent.com/d/";
export const PartenersImgs: Parteners[] = [
  { src1: url+"1r2h1gwDwJpmzs_HrHH-Gi75vk4yShKDG", src2: url+"16j7lE6PSfYLe7WSTENnam-ggRil_YaB4" }, 
  { src1: url+"1V3_k9Inwm0YZEKiGnBWr-pDaLrBv7sXg", src2: url+"131Tz-1phVnbTmyWoaThU96Y6ExEsUzM8" }, 
  { src1: url+"1DpY3rlJAl3ua9uYkHFD0KaeHhkAg6sJr", src2: url+"1v3slWeUYwb7FvbTiVki-NCKb0n6TTWot" }, 
  { src1: url+"1dNd-5ZtSSNrKGEaBYJ3dnW7Brv7-asYw", src2: url+"1kh_8Zk26UbPDAX2M3IjMDqvuXhvuTxlO" }, 
  { src1: url+"1pYG99CsgfPWzxBH1i5-_XHUOiguJsCxZ", src2: "" }
];

export const SocietyImgs: Parteners[] = [
  { src1: url+"1gEYt7BkMW82RQiHoWOaDX6CYEmHEMyPy", src2: url+"1X72Zz5b2IxbdwgPTdBfCe7VMNwDCexFT" }, 
  { src1: url+"1JYkO4hru9L562gXwAxgpoVT0XXC72lFB", src2: url+"1WZeqabKHgzbbRfeF4_IY8IU5qOhL4En-" }, 
  { src1: url+"16Wpf3OpCbTQfLRuwTkT-8OU94xyhVuCn", src2: url+"1q-rduVx4IhBEYYcqownvFZ7ybw9Yybk8" }, 
  { src1: url+"1EiAV6se4talNmnDXi_4ugythds1p6PFL", src2: "" }
];

