const url = "https://lh3.googleusercontent.com/d/";
const LEARNMODELSBASE: LearnModel[] = [{
    picture : url+"17-bIPHcf5MqQeD0uTzFeBNjF2FO_mDHs",
    title : "PEER LEARNING",
    message : "Le peer learning est un pillier de l'apprentissage à Codeloccol. En travaillant ensemble à resoudre les problèmes, les apprenants acquièrent l'esprit d'équipe et envisagent différentes approches pour résoudre un même problème. Enfin, en expliquant à leurs pairs ils maitrisent de mieux en mieux leur sujet."
  },{
    picture : url+"1rGrLJf-NaJTWE_2QEfcp_7x-9JJKMFsb",
    title : "PROJECT BASED",
    message : "Nous apprenons en pratiquant. A Codeloccol cette pédagogie est implementé dès le premier jour. Pour chaque block de connaissance, l'apprenant doit finaliser un certain nombre de projets. Il apprend à maitriser son sujet en resolvant différents problèmes."
  },{
    picture : url+"1gsITDIwKC4bKXoYRu1lRZNM5q44GJcsG",
    title : "COACHING",
    message : "Le code est un art. Chacun y va à sa manière. Cependant il existe des standards, des pratiques qui facilitent le travail en équipe et la maintenabilité de l'application. Les coachs sont là pour ça. Ils accompagnent l'apprenant à parfaire son code et à être prêt pour le monde professionnel."
  }
]

export default LEARNMODELSBASE
