import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
const MobileAuth: FunctionComponent = () => {
  return (
    <>
      <header className="header">
        <nav className={`navbar fixed-top navbar-expand-lg navbar-light bg-white border-bottom border-success border-2}`}>
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img
                src="https://drive.google.com/uc?export=view&id=1tr2OrSke7D-GQCw5AuZV1qdNYnF2s8vL"
                alt="Logo Codeloccol"
                width={178}
                height={45}
              />
            </Link>
            <div className="user">
              <label htmlFor="btn" className="button">
                <img src="https://drive.google.com/uc?export=view&id=1J9A2xJsHcZkiV6_qgORQA8h4TlSF2-71" alt="avatar" />
                <span className="icon">
                </span>
              </label>
              <input type="checkbox" className="checked" id="btn" />
              <ul>
                <li>
                  <Link to="/user/sign_in" onClick={() => { sessionStorage.removeItem('__SECURE'); }}>
                    <span className="icon">
                    </span>
                    <span className="title">Déconnexion</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default MobileAuth