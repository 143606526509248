import { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import Wizard from "./wizardTest/wizard";
import "./authUserTest.css";
import { AllQuiz } from "../../../models/test";

interface Props {
  setLoaderForm: (loaderForm: boolean) => void;
}

const AuthUserTest: FunctionComponent<Props> = () => {
  const today: any = new Date();
  const start: any = new Date("January 28, 2023 00:00:00 GMT+1:00");

  const [step, setStep] = useState<number>(0);
  const [counter, setCounter] = useState<number>(
    Math.floor((start - today) / 1000)
  );
  const [see, setSee] = useState<boolean>(false);
  const [begin, setBegin] = useState<boolean>(false);
  const [seeBtn, setSeeBtn] = useState<boolean>(false);

  let localLogic: QuestionReponse[] = [],
    localAlgo: QuestionReponse[] = [];
  AllQuiz.filter((all: Quiz, index: number) => index < 10).map(
    (ques) =>
      (localLogic = [...localLogic, { question: ques.question, reponse: "" }])
  );
  AllQuiz.filter((all: Quiz, index: number) => index > 10).map(
    (ques) =>
      (localAlgo = [...localAlgo, { question: ques.question, reponse: "" }])
  );
  if (localStorage.getItem("logic") === null)
    localStorage.setItem("logic", JSON.stringify(localLogic));
  const localLogicForm: QuestionReponse[] = JSON.parse(
    localStorage.getItem("logic") || JSON.stringify([])
  );
  if (localStorage.getItem("algo") === null)
    localStorage.setItem("algo", JSON.stringify(localAlgo));
  const localAlgoForm: QuestionReponse[] = JSON.parse(
    localStorage.getItem("algo") || JSON.stringify([])
  );

  const [logicForm, setLogicForm] = useState<AlgoQuiz>({
    question1: { value: localLogic[0].reponse, isValid: true },
    question2: { value: localLogic[1].reponse, isValid: true },
    question3: { value: localLogic[2].reponse, isValid: true },
    question4: { value: localLogic[3].reponse, isValid: true },
    question5: { value: localLogic[4].reponse, isValid: true },
    question6: { value: localLogic[5].reponse, isValid: true },
    question7: { value: localLogic[6].reponse, isValid: true },
    question8: { value: localLogic[7].reponse, isValid: true },
    question9: { value: localLogic[8].reponse, isValid: true },
    question10: { value: localLogic[9].reponse, isValid: true },
  });

  const [algoForm, setAlgoForm] = useState<AlgoQuiz>({
    question1: { value: localAlgo[0].reponse, isValid: true },
    question2: { value: localAlgo[1].reponse, isValid: true },
    question3: { value: localAlgo[2].reponse, isValid: true },
    question4: { value: localAlgo[3].reponse, isValid: true },
    question5: { value: localAlgo[4].reponse, isValid: true },
    question6: { value: localAlgo[5].reponse, isValid: true },
    question7: { value: localAlgo[6].reponse, isValid: true },
    question8: { value: localAlgo[7].reponse, isValid: true },
    question9: { value: localAlgo[8].reponse, isValid: true },
    question10: { value: localAlgo[9].reponse, isValid: true },
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, str: string) => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: Field = {
      [fieldName]: { value: fieldValue, isValid: true },
    };
    const index = fieldName.replace("question", "");
    if (str === "logic") {
      setLogicForm({ ...logicForm, ...newField });
      localLogicForm[+index - 1].reponse = fieldValue;
      localStorage.setItem("logic", JSON.stringify(localLogicForm));
    } else {
      setAlgoForm({ ...algoForm, ...newField });
      localAlgoForm[+index - 1].reponse = fieldValue;
      localStorage.setItem("algo", JSON.stringify(localAlgoForm));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((seconds) => seconds - 1);
    }, 1000);
    if (counter < 0 && counter > -60 * 60 * 24 * 20) {
      setBegin(true);
      setSeeBtn(true);
    }
    if (counter < -60 * 60 * 24 * 20) {
      setBegin(false);
      setSeeBtn(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [counter]);

  return (
    <>
      {!see && (
        <div className="est">
          <span className="loader"></span>
        </div>
      )}
      <section className={`test ${!see && "d-none"}`}>
        <div className="container py-5 mx-0">
          <div className="row">
            <div className="main_title_2">
              <span>
                <em />
              </span>
              <h2>Test d'amission</h2>
            </div>
          </div>
          {begin ? (
            <Wizard
              handleInputChange={handleInputChange}
              step={step}
              setStep={setStep}
              setSee={setSee}
              see={see}
              seeBtn={seeBtn}
            />
          ) : (
            <>
              {counter > 0 ? (
                <div className="row mx-4 justify-content-evenly">
                  <div className="col col-sm-12 text-center">
                    <h4>
                      Le test débutera le Samedi 28 janvier 2023 à 00:00:00
                      <br />
                      Il sera clos le Lundi 30 janvier 2023 à 23:59:29
                    </h4>
                  </div>
                </div>
              ) : (
                <Wizard
                  handleInputChange={handleInputChange}
                  step={step}
                  setStep={setStep}
                  setSee={setSee}
                  see={see}
                  seeBtn={seeBtn}
                />
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default AuthUserTest;
