import { FunctionComponent, ChangeEvent } from 'react'
import IdentityWizard from './identity'
import IsHandicapWizard from './isHandicap'
import HandicapWizard from './handicap'
import CodingWizard from './coding'
import IsLearnCodingWizard from './isLearnCoding'
import AdventureWizard from './adventure'
import WhyDoNotLearnCodingWizard from './whyDonotLearnCoding'
import LearnIsToPraticeWizard from './learnIsToPractice'
import LikeToAchieveWizard from './likeToAchieve'
import TechEnvironmentCountryWizard from './techEnvironmentCountry'
import SubmitWizard from './submitWizard'
import SuccessWizard from './succesWizard'

interface Props {
  step: number;
  admissionForm : Admission;
  setAdmissionForm : (admission : Admission)=> void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) =>void;
  handleTextareaChange: (e: ChangeEvent<HTMLTextAreaElement>) =>void;
  wordCount: (str:string) => number
  validate : boolean
  setValidate : (essai:boolean)=> void;
  nextStep: () => void;
  setSubmit: (submit:boolean)=> void
  contract: boolean
  setContract: (contract: boolean) => void
  setLoaderForm: (loaderFoem: boolean)=> void
  setCode : (code : number) => void
}

const Wizard: FunctionComponent<Props> = ({step, setCode, admissionForm, setAdmissionForm, handleInputChange, handleTextareaChange, wordCount, validate, setValidate, nextStep, setSubmit, contract, setContract, setLoaderForm}) => {

  switch (step) {
    case 1:
      return <IdentityWizard setLoaderForm={setLoaderForm} setCode={setCode} admissionForm={admissionForm} handleInputChange={handleInputChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} />
    case 2:
      return <IsHandicapWizard  admissionForm={admissionForm} handleInputChange={handleInputChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} />
    case 3:
      return <HandicapWizard  admissionForm={admissionForm} handleInputChange={handleInputChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} />
    case 4:
      return <CodingWizard  admissionForm={admissionForm} handleTextareaChange={handleTextareaChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} wordCount={wordCount} />
    case 5:
      return <IsLearnCodingWizard  admissionForm={admissionForm} handleInputChange={handleInputChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} />
    case 6:
      return <AdventureWizard  admissionForm={admissionForm} handleTextareaChange={handleTextareaChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} wordCount={wordCount} />
    case 7:
      return <WhyDoNotLearnCodingWizard  admissionForm={admissionForm}  handleTextareaChange={handleTextareaChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} wordCount={wordCount} />
    case 8:
      return <LearnIsToPraticeWizard  admissionForm={admissionForm} validate={validate} setValidate={setValidate} nextStep= {nextStep} handleTextareaChange={handleTextareaChange} wordCount={wordCount} />
    case 9:
      return <LikeToAchieveWizard  admissionForm={admissionForm} handleTextareaChange={handleTextareaChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} wordCount={wordCount} />
    case 10:
      return <TechEnvironmentCountryWizard  admissionForm={admissionForm} handleTextareaChange={handleTextareaChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} wordCount={wordCount} />
    case 11:
        return <SubmitWizard admissionForm={admissionForm} setAdmissionForm={setAdmissionForm} validate={validate} setValidate={setValidate} setSubmit={setSubmit} contract={contract} setContract={setContract} />
    case 121:
      return <SuccessWizard />
    default:
      return <IdentityWizard setLoaderForm={setLoaderForm} setCode={setCode} admissionForm={admissionForm} handleInputChange={handleInputChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} />
  }
}
export default Wizard