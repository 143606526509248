import { FunctionComponent, useState } from "react"
import AuthLayout from "./authLayout";
import AuthUserMeet from "../components/authUser/authUserMeet/authUserMeet";
import NavBarUser from "../components/template/navBar/navBarUser";
import SubMenuUser from "../components/template/subMenu/subMenuUser";



const UserMeetPage: FunctionComponent= () => {
  const [loaderForm, setLoaderForm]= useState<boolean>(false);
  return <AuthLayout content={<AuthUserMeet />} navbar={<NavBarUser />} submenu={<SubMenuUser />} loaderForm={loaderForm} />
} 
export default UserMeetPage