import { FunctionComponent, useState } from "react"
import AuthLayout from "./authLayout";
import AuthUserPiscine from "../components/authUser/authUserPiscine/authUserPiscine";
import NavBarUser from "../components/template/navBar/navBarUser";
import SubMenuUser from "../components/template/subMenu/subMenuUser";



const UserPiscinePage: FunctionComponent= () => {
  const [loaderForm, setLoaderForm]= useState<boolean>(false);
  return <AuthLayout content={<AuthUserPiscine />} navbar={<NavBarUser />} submenu={<SubMenuUser />} loaderForm={loaderForm} />
} 
export default UserPiscinePage