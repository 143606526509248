import { FunctionComponent } from "react";
import "./banner.css"

const AboutBanner: FunctionComponent = () => {
  return (
    <section id="about_banner" className="parallax_window_in">
      <div id="sub_content_in">
        <h1>Codeloccol, c'est quoi ? 
        </h1>
        <p>
          Nous sommes une organisation à but non lucratif qui vise à sélectionner et former des jeunes du Niger et d'Afrique aux métiers de codeur. 
        </p>
      </div>
    </section>
  );
};

export default AboutBanner;
