import { FunctionComponent, useState } from "react"
import AuthLayout from "./authLayout";
import AuthUserTest from "../components/authUser/authUserTest/authUserTest";
import NavBarUser from "../components/template/navBar/navBarUser";
import SubMenuUser from "../components/template/subMenu/subMenuUser";

const UserTestPage: FunctionComponent= () => {
  const [loaderForm, setLoaderForm]= useState<boolean>(false);
  return <AuthLayout content={<AuthUserTest setLoaderForm={setLoaderForm} />} navbar={<NavBarUser />} submenu={<SubMenuUser />} loaderForm={loaderForm} />
} 
export default UserTestPage