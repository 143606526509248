import { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface Props {
  query: Quiz;
  step: number;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>, str: string) => void;
  str: string;
  sub: boolean;
}

const Quiz: FunctionComponent<Props> = ({
  query,
  handleInputChange,
  step,
  str,
  sub,
}) => {
  const localLogicForm: QuestionReponse[] = JSON.parse(
    localStorage.getItem("logic") || JSON.stringify([])
  );
  const localAlgoForm: QuestionReponse[] = JSON.parse(
    localStorage.getItem("algo") || JSON.stringify([])
  );

  const [select, setSelect] = useState<string>(
    str === "logic"
      ? localLogicForm[step - 1].reponse
      : step > 0
      ? localAlgoForm[step - 1].reponse
      : ""
  );

  const onOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelect(e.target.value);
  };

  return (
    <div className="row justify-content-evenly">
      <div className="col-lg-8">
        <>
          {query.allResponse.length === 0 ? (
            <p>{query.question}</p>
          ) : (
            <>
              {query.question.startsWith("https") ? (
                <>
                  <span className="question">
                    Question {step} <FontAwesomeIcon icon={faArrowRight} />
                  </span>{" "}
                  <br />
                  <img src={query.question.split("<br />")[0]} />
                  <p>{query.question.split("<br />")[1]}</p>
                </>
              ) : (
                <p>
                  <span className="question">
                    Question {step} <FontAwesomeIcon icon={faArrowRight} />
                  </span>{" "}
                  <span
                    className="quiz"
                    dangerouslySetInnerHTML={{ __html: query.question }}
                  ></span>
                </p>
              )}
            </>
          )}
        </>
        <div className="form-group">
          <>
            {query.allResponse.map((response) => (
              <>
                {sub && select === "" && (
                  <>
                    <span className="error">Obligatoire</span>
                  </>
                )}
                <label className="container_radio version_2">
                  <input
                    type="radio"
                    name={`question${step}`}
                    value={response}
                    onChange={(e) => {
                      handleInputChange(e, str);
                      onOptionChange(e);
                    }}
                    checked={select === response}
                  />
                  {response}
                  <span className="checkmark" />
                </label>
              </>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};
export default Quiz;
