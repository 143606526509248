import { FunctionComponent, ChangeEvent } from 'react'
import IdentityWizard from './identityGirl'
import WhyWizard from './why'
import AdventureWizard from './you'
import TechEnvironmentCountryWizard from './girlInDigital'
import SubmitWizard from './submitWizard'
import SuccessWizard from './succesWizard'
import WelcomeWizard from './welcomeWizard'

interface Props {
  step: number;
  admissionForm : Admission;
  setAdmissionForm : (admission : Admission)=> void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) =>void;
  handleTextareaChange: (e: ChangeEvent<HTMLTextAreaElement>) =>void;
  wordCount: (str:string) => number
  validate : boolean
  setValidate : (essai:boolean)=> void;
  nextStep: () => void;
  setSubmit: (submit:boolean)=> void
  contract: boolean
  setContract: (contract: boolean) => void
  setLoaderForm: (loaderFoem: boolean)=> void
  setCode : (code : number) => void
}

const Wizard: FunctionComponent<Props> = ({step, setCode, admissionForm, setAdmissionForm, handleInputChange, handleTextareaChange, wordCount, validate, setValidate, nextStep, setSubmit, contract, setContract, setLoaderForm}) => {

  switch (step) {
    case 1:
      return <WelcomeWizard admissionForm={admissionForm} nextStep= {nextStep} />

    case 2:
      return <IdentityWizard setLoaderForm={setLoaderForm} setCode={setCode} admissionForm={admissionForm} handleInputChange={handleInputChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} />
    
    case 3:
      return <WhyWizard  admissionForm={admissionForm} handleTextareaChange={handleTextareaChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} wordCount={wordCount} />
    case 4:
      return <AdventureWizard  admissionForm={admissionForm} handleTextareaChange={handleTextareaChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} wordCount={wordCount} />
    case 5:
      return <TechEnvironmentCountryWizard  admissionForm={admissionForm} handleTextareaChange={handleTextareaChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} wordCount={wordCount} />
    case 6:
        return <SubmitWizard admissionForm={admissionForm} setAdmissionForm={setAdmissionForm} validate={validate} setValidate={setValidate} setSubmit={setSubmit} contract={contract} setContract={setContract} />
    case 7:
      return <SuccessWizard />
    default:
      return <IdentityWizard setLoaderForm={setLoaderForm} setCode={setCode} admissionForm={admissionForm} handleInputChange={handleInputChange} validate={validate} setValidate={setValidate} nextStep= {nextStep} />
  }
}
export default Wizard