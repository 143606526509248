import jwt_decode from 'jwt-decode';

export const isAuthentificated = () => {
  const SECURE = sessionStorage.getItem('__SECURE') || "";
  const decoded: any = SECURE === "" ? {} : jwt_decode(SECURE);
  if (decoded.hasOwnProperty("exp") && decoded.hasOwnProperty("_id") && decoded.hasOwnProperty("profil") && decoded.hasOwnProperty("mail") && (decoded.exp > Date.now() / 1000)) {
    const { _id, name, profil, mail } = decoded;
    return {
      _id: _id,
      name: name,
      profil: profil,
      mail: mail,
      isAuth: true
    }
  } else {
    sessionStorage.removeItem('__SECURE');
    return { isAuth: false }
  }
}