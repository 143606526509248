import { FunctionComponent, useState } from "react"
import AuthLayout from "./authLayout";
import AuthUserInfo from "../components/authUser/authUserInfo/authUserInfo";
import NavBarUser from "../components/template/navBar/navBarUser";
import SubMenuUser from "../components/template/subMenu/subMenuUser";



const UserInfoPage: FunctionComponent= () => {
  const [loaderForm, setLoaderForm]= useState<boolean>(false);
  return <AuthLayout content={<AuthUserInfo setLoaderForm={setLoaderForm} />} navbar={<NavBarUser />} submenu={<SubMenuUser />} loaderForm={loaderForm} />
} 
export default UserInfoPage