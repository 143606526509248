import { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { AdmissionService } from "../../../services/AdmissonServices";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { CountryService } from "../../../services/CountryService"


interface Props {
  admissionForm: Admission
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  validate: boolean
  setValidate: (validate: boolean) => void
  nextStep: () => void
  setLoaderForm: (loaderForm: boolean) => void
  setCode : (code : number) => void
}

const IdentityWizard: FunctionComponent<Props> = ({ admissionForm, setCode, handleInputChange, validate, nextStep, setValidate, setLoaderForm }) => {
  const countryService = new CountryService()
  const [identity, setIdentity] = useState<Identity>({ name: admissionForm.name, mail: admissionForm.mail, country: admissionForm.country, city: admissionForm.city, mobile: admissionForm.mobile, age: admissionForm.age, gender: admissionForm.gender
  });
  const [gender, setGender] = useState(admissionForm.gender.value)
  const [after, setAfter] = useState<boolean>(false);
  const [country, setCountry] = useState<Countries[]>([]);
  const [dial, setDial] = useState<string>("");
  const [city, setCity] = useState<string[]>([]);
  
  useEffect(() => {
    countryService.getAllCountries().then(datas => {
      let allCountries: Countries[] = [];
      if (datas.data) {
        datas.data.map((country: { name: string; dial_code: string }) => {
          allCountries = [...allCountries, {name : country.name, dial_code : country.dial_code}]
        })
        setCountry(allCountries);
      }
    });
  }, []);

  useEffect(()=> {
    if (admissionForm.country.value && country.length > 0) returnCountry(admissionForm.country.value);
  }, [country])

  const returnCountry = (pays: string) => {
    const trueCountry = country.filter(c => c.name.toLocaleLowerCase() === pays.trim().toLocaleLowerCase());
    if (trueCountry.length > 0) {
      const dial = country.filter(cc => cc.name.toLocaleLowerCase() === trueCountry[0].name.toLocaleLowerCase());
      setCode(+dial[0].dial_code)
      setDial(dial[0].dial_code);
      setLoaderForm(true);
      countryService.getAllCitiesOfCountry(pays).then((datas : ApiCountryCities) => {
        let data = datas.data;
        data.map((c: string, i: number) => {
          data[i] = c.replace(/Département de|Département d\'|Department|Province du|Province/gi, "").trim();
        });
        setCity(removeCityDuplicate(data).sort());
      })
      setLoaderForm(false);
    } else {
      setCity([]);
      admissionForm.city.value = ""
      setDial("");
    }
  }

  const removeCityDuplicate = (arr : string []) => {
    let data : string [] = [];
    arr.map(a => { if(data.indexOf(a) === -1) data = [...data, a] })
    return data;
  }

  useEffect(() => {
    if (validate) {
      validateForm();
      setAfter(true);
    }
    if (after) validateForm();
    setValidate(false);
  }, [validate, after, admissionForm]);

  const validateForm = () => {
    let newForm: Identity = identity;
    let names = admissionForm.name.value ? admissionForm.name.value.trim().split(/[\s]+/) : "";
    if(!names[1]) names = [...names, ""];
    if (!/^[\wéèê\s]{3}/.test(names[0]) || (!/^[\wéèê\s]{3}/.test(names[1]))) {
      const errorMsg: string = `Tu dois saisir tes nom et prénom`;
      const newField: Field = { value: admissionForm.name.value, error: errorMsg, isValid: false };
      newForm = { ...newForm, ...{ name: newField } };
    } else {
      const newField: Field = { value: admissionForm.name.value, error: '', isValid: true };
      newForm = { ...newForm, ...{ name: newField } };
    }

    if (!/^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(admissionForm.mail.value)) {
      const errorMsg: string = `Tu dois saisir une adresse mail correct`;
      const newField: Field = { value: admissionForm.mail.value, error: errorMsg, isValid: false };
      newForm = { ...newForm, ...{ mail: newField } };
    } else {
      const newField: Field = { value: admissionForm.mail.value, error: '', isValid: true };
      newForm = { ...newForm, ...{ mail: newField } };
    }

    const countryValue =  admissionForm.country.value ? admissionForm.country.value.trim().toLowerCase().replace(/\b(\w)/g, (s: string) => s.toUpperCase()) : ""
    
    if (country.filter(cc => cc.name === countryValue).length === 0) {
      const errorMsg: string = `Indiques ton pays de résidence`;
      const newField: Field = { value: admissionForm.country.value, error: errorMsg, isValid: false };
      newForm = { ...newForm, ...{ country: newField } };
    } else {
      const newField: Field = { value: admissionForm.country.value, error: '', isValid: true };
      newForm = { ...newForm, ...{ country: newField } };
    }

    if (city.indexOf(admissionForm.city.value ? admissionForm.city.value.trim().toLowerCase().replace(/\b(\w)/g, (s: string) => s.toUpperCase()): "") === -1) {
      const errorMsg: string = `Indiques ta ville de résidence`;
      const newField: Field = { value: admissionForm.city.value, error: errorMsg, isValid: false };
      newForm = { ...newForm, ...{ city: newField } };
    } else {
      const newField: Field = { value: admissionForm.city.value, error: '', isValid: true };
      newForm = { ...newForm, ...{ city: newField } };
    }

    if (!/^[\d\s+]{8,12}/.test(admissionForm.mobile.value)) {
      const errorMsg: string = `Tu dois saisir un numéro de téléphone correct`;
      const newField: Field = { value: admissionForm.mobile.value, error: errorMsg, isValid: false };
      newForm = { ...newForm, ...{ mobile: newField } };
    } else {
      const newField: Field = { value: admissionForm.mobile.value, error: '', isValid: true };
      newForm = { ...newForm, ...{ mobile: newField } };
    }

    if (admissionForm.age.value === undefined || (admissionForm.age.value !== undefined && admissionForm.age.value.trim() === "") || (admissionForm.age.value && admissionForm.age.value && (admissionForm.age.value < 18 || admissionForm.age.value > 45))) {
      const errorMsg: string = admissionForm.age.value < 18 ? `Avoir au moins 18` : "Vous êtes trop agés";
      const newField: Field = { value: admissionForm.age.value, error: errorMsg, isValid: false };
      newForm = { ...newForm, ...{ age: newField } };
    } else {
      const newField: Field = { value: admissionForm.age.value, error: '', isValid: true };

      newForm = { ...newForm, ...{ age: newField } };
    }

    if (!/^Homme|Femme/.test(admissionForm.gender.value)) {
      const errorMsg: string = `Indiques ton genre ici`;
      const newField: Field = { value: admissionForm.gender.value, error: errorMsg, isValid: false };
      newForm = { ...newForm, ...{ gender: newField } };
    } else {
      const newField: Field = { value: admissionForm.gender.value, error: '', isValid: true };
      newForm = { ...newForm, ...{ gender: newField } };
    }

    setIdentity(newForm);
    const validateF = newForm.name.isValid && newForm.mail.isValid && newForm.country.isValid && newForm.city.isValid && newForm.mobile.isValid && newForm.age.isValid && newForm.gender.isValid
    if (validateF) {
      const copy = identity;
      if (copy.mail.isValid && copy.mobile.isValid) {
        const admissionService = new AdmissionService();
        const admission = {
          mail: admissionForm.mail.value,
          mobile: admissionForm.mobile.value
        }
        setLoaderForm(true);
        admissionService.existeAdmission(admission).then(response => {
          if (response.type) {
            for (let obj in response.existe) {
              if (response.existe[obj].mail === admission.mail) {
                copy.mail = { error: `Ce mail ${admission.mail} existe deja dans notre liste d'admission`, value: "", isValid: false };
                admissionForm.mail.value = '';
              }

              if (response.existe[obj].mobile === +admission.mobile) {
                copy.mobile = { error: `Ce numero ${admission.mobile} existe deja dans notre liste d'admission`, value: "", isValid: false };
                admissionForm.mobile.value = '';
              }
            }
            setIdentity(copy);
          } else {
            nextStep();
          }
          setLoaderForm(false);
        });
      }
    };
    return validateF;
  }

  const checkCountry = (e : ChangeEvent<HTMLInputElement>) => {
    if(e.target.value !== ""){
      const res = country.filter(cc => cc.name.toLocaleLowerCase() === e.target.value.trim().toLocaleLowerCase())
      if(res.length === 0) {
        e.target.value = admissionForm.country.value = "";
      }
    }
  }

  const checkCity = (e : ChangeEvent<HTMLInputElement>) => {
    if(e.target.value !== ""){
      const res = city.filter(cc => cc.toLocaleLowerCase() === e.target.value.trim().toLocaleLowerCase())
      if(res.length === 0) {
        const cite = e.target;
        cite.value = admissionForm.city.value = "";
      }
    }
  }

  const onOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value)
  }

  return (
    <div className="step">
      <h3 className="main_question">
        <FontAwesomeIcon icon={faArrowRight} />
        Salut jeune padawan !
        Dis nous qui tu es ?
      </h3>
      <div className="form-group add_top_30">
        <label>Nom et Prénom</label>
        <input
          type="text"
          name="name"
          value={admissionForm.name.value}
          onChange={(e) => handleInputChange(e)}
          className="form-control required"
        />
        {
          !identity.name.isValid && (
            <>
              {
                identity.name.value && identity.name.value.trim() === "" ? (
                  <span className="error"> Ce champs est obligatoire </span>
                ) : (
                  <span className="error"> {identity.name.error} </span>
                )
              }
            </>
          )
        }
      </div>
      <div className="form-group">
        <label>Courriel</label>
        <input
          type="email"
          name="mail"
          className="form-control required"
          value={admissionForm.mail.value}
          onChange={(e) => handleInputChange(e)}
        />
        {
          !identity.mail.isValid && (
            <>
              {
                identity.mail.value && identity.mail.value.trim() === "" && !identity.mail.error?.includes("existe") ? (
                  <span className="error"> Ce champs est obligatoire </span>
                ) : (
                  <span className="error"> {identity.mail.error} </span>
                )
              }
            </>
          )
        }
      </div>
      <div className="form-group">
        <label>Pays</label>
        <input
          name="country"
          className="countrypicker form-control required"
          onChange={(e) => {
            handleInputChange(e);
            returnCountry(e.target.value);
          }}
          onBlur={(e)=> checkCountry(e)}
          value={admissionForm.country.value}
          list="country"
        />
        <datalist id="country">
          {
            country.map(c => <option value={c.name}></option>)
          }
        </datalist>
        {
          !identity.country.isValid && (
            <>
              {
                identity.country.value && identity.country.value.trim() === "" ? (
                  <span className="error"> Ce champs est obligatoire </span>
                ) : (
                  <span className="error"> {identity.country.error} </span>
                )
              }
            </>
          )
        }
      </div>
      <div className="form-group">
        <label>Ville</label>
        <input
          type="text"
          name="city"
          value={admissionForm.city.value}
          onChange={(e) => {
            handleInputChange(e);
          }}
          onInput = {()=> returnCountry(admissionForm.country.value)}
          onBlur={(e)=> checkCity(e)}
          className="form-control required"
          list="city"
        />
        <datalist id="city">
          {
            city.map(c => <option value={c}></option>)
          }
        </datalist>
        {
          !identity.city.isValid && (
            <>
              {
                identity.city.value && identity.city.value.trim() === "" ? (
                  <span className="error"> Ce champs est obligatoire </span>
                ) : (
                  <span className="error"> {identity.city.error} </span>
                )
              }
            </>
          )
        }
      </div>
      <div className="form-group">
        <label>Mobile (whatsapp)</label>
        <div className="input-group">
          <span className={`input-group-text dial ${dial.length === 0 ? "d-none" : ""}`}>{dial}</span>
          <input
            type="text"
            name="mobile"
            value={admissionForm.mobile.value}
            onChange={(e) => handleInputChange(e)}
            className="form-control required"
          />
        </div>
        {
          !identity.mobile.isValid && (
            <>
              {
                identity.mobile.value && identity.mobile.value.trim() === "" && !identity.mobile.error?.includes("existe") ? (
                  <span className="error"> Ce champs est obligatoire </span>
                ) : (
                  <span className="error"> {identity.mobile.error} </span>
                )
              }
            </>
          )
        }
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-5">
          <label>Age</label>
          <div className="form-group radio_input">
            <input
              type="text"
              name="age"
              className="form-control required"
              value={admissionForm.age.value}
              onChange={(e) => handleInputChange(e)}
            />
            {
              !identity.age.isValid && (
                <>
                  {
                    !identity.age.value ? (
                      <span className="error decal"> Indiques ton âge ici </span>
                    ) : (
                      <span className="error decal"> {identity.age.error} </span>
                    )
                  }
                </>
              )
            }
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-6 col-xs-7">
          <label>Genre</label>
          <div className="form-group radio_input">
            {
              !identity.gender.isValid && (
                <>
                  {
                    identity.gender.value && identity.gender.value.trim() === "" ? (
                      <span className="error decal"> Obligatoire </span>
                    ) : (
                      <span className="error decal"> {identity.gender.error} </span>
                    )
                  }
                </>
              )
            }
            <label className="container_radio me-3">
              Homme
              <input
                type="radio"
                name="gender"
                className="required"
                value={"Homme"}
                onChange={e => {handleInputChange(e); onOptionChange(e)}}
                checked={gender === "Homme"}
              />
              <span className="checkmark" />
            </label>
            <label className="container_radio">
              Femme
              <input
                type="radio"
                name="gender"
                className="required"
                value={"Femme"}
                onChange={e => {handleInputChange(e); onOptionChange(e)}}
                checked={gender === "Femme"}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IdentityWizard;
