import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import 'react-multi-carousel/lib/styles.css';

import Parteners from "./partener";
import STUDENTSBASE from "../../models/students"
import Student from "./student";
import { PartenersImgs, SocietyImgs } from "../../models/parteners";

import LEARNMODELSBASE from "../../models/learns";
import LearnModel from "./learModel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import "./home.css"

interface Props {
  counter: number
}

const HomeContent: FunctionComponent<Props> = ({ counter }) => {

  const [pImages, setPImages] = useState<Parteners[]>(PartenersImgs);
  const [sImages, setSImages] = useState<Parteners[]>(SocietyImgs);
  const [parteners, setParteners] = useState<Parteners[]>([]);
  const [society, setSociety] = useState<Parteners[]>([]);
  const [part, setPart] = useState<boolean>(true);
  const [soc, setSoc] = useState<boolean>(true);
  const [partPosition, setPartPosition] = useState<number>(0);
  const [socPosition, setSocPosition] = useState<number>(0);
  const [suiv, setSuiv] = useState<boolean>(false)
  const [prec, setPrec] = useState<boolean>(false)


  const imgPartPosition = pImages.length - 1;
  const position = PartenersImgs[imgPartPosition];
  const imgSocPosition = sImages.length - 1;
  const sPosition = SocietyImgs[imgSocPosition];


  useEffect(() => {
    if (part || soc) {

      if (part) {
        setParteners(pImages.filter((src, i) => i < 4 && src));
        setPart(!part);
        setPartPosition(0);
        for (let i = 0; i < imgPartPosition; i++) {
          if (pImages[i].src1 === position.src1 && pImages[i].src2 === position.src2) {
            const progess = imgPartPosition - i;
            setPartPosition((progess * 100) / imgPartPosition);
            break;
          }
        }
        setTimeout(() => {
          setSuiv(false)
          setPrec(false)
        }, 700);
      }

      if (soc) {
        setSociety(sImages.filter((src, i) => i < 4 && src));
        setSoc(!soc);
        setSocPosition(0);
        for (let i = 0; i < imgSocPosition; i++) {
          if (sImages[i].src1 === sPosition.src1 && sImages[i].src2 === sPosition.src2) {
            const progess = imgSocPosition - i;
            setSocPosition((progess * 100) / imgSocPosition);
            break;
          }
        }
      }
    }
  }, [part, soc, pImages, imgPartPosition, imgSocPosition, sImages, sPosition, position]);


  const prev = (bool: boolean) => {
    let copyParteners = [...pImages], copySociety = [...sImages];
    const lastItem = bool ? copyParteners.pop() : copySociety.pop();
    if (lastItem) {
      if (bool) {
        setPImages([lastItem, ...copyParteners]);
        setPrec(!prec)
        setPart(!part);
      } else {
        setSImages([lastItem, ...copySociety]);
        setSoc(!soc);
      }
    }
  }

  const next = (bool: boolean) => {
    let copyParteners = [...pImages], copySociety = [...sImages];
    const firstItem = bool ? copyParteners.shift() : copySociety.shift();
    if (firstItem) {
      if (bool) {
        setPImages([...copyParteners, firstItem]);
        setSuiv(!suiv)
        setPart(!part);
      } else {
        setSImages([...copySociety, firstItem]);
        setSoc(!soc);
      }
    }
  }

  const timeConvert = (time: number) => {
    if (time > 0) {
      let hh: string, mm: string, ss: string, dd: string;
      let days: number = Math.floor(time / (60 * 60 * 24));
      let hours: number = Math.floor((time - (days * 60 * 60 * 24)) / (60 * 60));
      let minutes: number = Math.floor((time - ((days * 60 * 60 * 24 + hours * 60 * 60))) / 60);
      let seconds: number = Math.floor(time - ((days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60)));
      (days > 1) ? dd = `${days} jours` : dd = `${days} jours`;
      (hours < 10) ? hh = `0${hours}` : hh = `${hours}`;
      (minutes < 10) ? mm = `0${minutes}` : mm = `${minutes}`;
      (seconds < 10) ? ss = `0${seconds}` : ss = `${seconds}`;
      return `${dd} ${hh}:${mm}:${ss}`;
    }
  }

  return (
    <>
      <div className="container">
        
      <div className="row my-2">
          {
            counter > 0 ? (
              <>
                <div className="counter text-center">
                  <span className="first time"><h3>Temps restant</h3></span>
                  <div className="col-xs-12 d-flex justify-content-center">
                    <span className="first ff">
                      <h3>{timeConvert(counter)?.split(" ")[0]}</h3>
                      <p>Jours</p>
                    </span>
                    <span className="">
                      <h3>{timeConvert(counter)?.split(" ")[2].split(":")[0]}</h3>
                      <p>Heures</p>
                    </span>
                    <span>
                      <h3>{timeConvert(counter)?.split(" ")[2].split(":")[1]}</h3>
                      <p>Minutes</p>
                    </span>
                    <span>
                      <h3>{timeConvert(counter)?.split(" ")[2].split(":")[2]}</h3>
                      <p>Secondes</p>
                    </span>
                  </div>
                </div>
                <p className="btn-nav d-flex justify-content-center mb-4">
                  <Link to="/apply" target="_blank" className="p-2">
                    Candidater
                  </Link>
                </p>
              </>
            ) : (
              <>
                <div className="main_title_2">
                  <span>
                    <em />
                  </span>
                  <h2>Information importante</h2>
                </div>
                <div className="col-md-6">
                  <div className="txt-wrap">
                    <p>
                      <img className="w-100" src="https://lh3.googleusercontent.com/d/1LW96N6wcAbF4VIREm_2mw8tPoBX8RBhx" alt="candidature" />
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-wrap">
                    <ol className="text-justify">
                      <li className="mt-2">
                        <strong>Test d'entrée</strong>
                        <br />
                        La clôture pour le test d'entrée a été reporté pour le Mercredi 2 février 2023 à 23:59:59 delais de rigueur.
                      </li>
                      <li className="mt-2">
                        <strong>Entretien</strong> <br />
                        Le meet qui était prevu pour le Mercredi 1er fevrier 2023 a été reporté pour le Vendredi 03 février 2023 
                      </li>
                      <li className="mt-2">
                        <strong>Piscine</strong> <br />
                        La piscine qui était prevu pour le Mercredi 1er fevrier 2023 a été reportée pour le Lundi 06 Février 2023 
                      </li>
                      <li className="mt-2">
                        <strong>Formation</strong> <br />
                        La rentrée se fera le Mercredi 1er mars 2023
                      </li>
                    </ol>
                  </div>
                </div>
              </>
            )
          }
        </div>
        <div className="row mt-2">
          <div className="main_title_2">
            <span>
              <em />
            </span>
            <h2>Concept</h2>
          </div>
          <div className="col-md-6">
            <blockquote className="blockquote slide-right">
              <p className="blockquote__content">
                <FontAwesomeIcon className="quote rotate1" icon={faQuoteLeft} />
                {" "}Inspirée des écoles comme 42 et Andela, Codeloccol identifie les meilleurs potentiels pour les former au coding (programmation informatique). Le curriculum
                est mis en place par un nigérien formé à la Silicon Valley et validé par des partenaires du projet. Il intègre les dernières évolutions du Numériques. Des encadrants (Coach)
                et intervenants de hauts niveaux assisteront les étudiants ainsi que des mentors internationaux et de l’écosysteme local. Pour accéder á Codeloccol, il suffit de réussir
                LA sélection avoir la passion du code. Aucun diplome n’est requis. Les metiers de développeurs sont classés parmi les emplois les mieux rémunérés au monde et
                la demande dans ce secteur est forte croissance. <em>Mais devenir un bon développeur dans nos réalités est un travail de longue haleine, une lutte acharnée contre
                  la demotivation... voir la mer a boire</em>. De nos jours il y'a énormement de ressources pour se former mais qui sont en général trés avancées ou imcompletes, ce qui
                augmente d'autant plus la frustration de l’apprenant. Beaucoup sont passés par ces étapes et c’est nous voulons faciliter cela à nos apprenants. Grace à l’experience
                du concepteur de la plateforme, nous avons développé une solution qui répondra à toutes ces exigences. Une plateforme qui en plus de permettre à l’etudiant d’apprendre
                avec toute la motivation necessaire, repond aux besoins de l'écosystème numérique à la fois local et international.{" "}
                <FontAwesomeIcon className="quote rotate" icon={faQuoteLeft} />
              </p>
            </blockquote>
          </div>
          <div className="col-md-6">
            <div className="widget-container slide-right">
              <div className="image">
                <img
                  src="https://lh3.googleusercontent.com/d/1hWwglRzDtHp_fAzOL7CIwAr_8zcNNkvP"
                  className="w-100 img-fluid"
                  alt=""
                  sizes="(max-width: 3508px) 100vw, 3508px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="main_title_2">
            <span>
              <em />
            </span>
            <h2>Modéle d'apprentissage</h2>
          </div>
          <div className="sliderStart">
            <div className="content-slider">
              <div className="slider-wrapper slides-3 slick-initialized slick-slider slick-dotted" >
                <div className="slick-list draggable">
                  <div className="slick-track models">
                    {
                      LEARNMODELSBASE.map((learn, index) => <LearnModel key={index} learn={learn} />)
                    }
                  </div>
                </div>
              </div>
              <div className="slider-controls" style={{ top: 267 }}>
                <button type="button" className="slick-prev slick-arrow">
                  prev
                </button>
                <div className="slick-dots">
                  <ul className="slick-dots" role="tablist">
                    <li className="slick-active" role="presentation">
                      <button
                        type="button"
                        role="tab"
                        id="slick-slide-control00"
                        aria-controls="slick-slide00"
                        aria-label="1 of 2"
                        tabIndex={0}
                        aria-selected="true"
                      >
                        1
                      </button>
                    </li>
                    <li role="presentation">
                      <button
                        type="button"
                        role="tab"
                        id="slick-slide-control01"
                        aria-controls="slick-slide03"
                        aria-label="2 of 2"
                        tabIndex={-1}
                      >
                        2
                      </button>
                    </li>
                  </ul>
                </div>
                <button type="button" className="slick-next slick-arrow" style={{}}>
                  next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="main_title_2">
            <span>
              <em />
            </span>
            <h2>Comment nous rejoindre ?</h2>
          </div>
          <div className="col-md-6">
            <div className="text-wrap">
              <p>
                Pour rejoindre Codeloccol en tant qu'apprenant il y'a quatres étapes importantes à suivre :
              </p>
              <ol>
                <li>
                  <strong>Formulaire de candidature :</strong>
                  <br />
                  Remplir le formulaire de candidature en ligne
                </li>
                <li>
                  <strong>Test logique :</strong> <br />
                  Passer un test de logique basé sur des jeux permettant de jauger vos capacités psycotechnique.
                </li>
                <li>
                  <strong>Entretien :</strong> <br />
                  Passer un entretien de groupe qui vous présente l'école, sa pédagogie et surtout les principes de la piscine
                </li>
                <li>
                  <strong>Piscine :</strong> <br />
                  2 à 4 - semaines de selection pendant lesquelles vous apprendriez à coder à partir de 0 et vous aurez un mini projet à réaliser.
                </li>
              </ol>
            </div>
          </div>
          <div className="col-md-6">
            <div className="txt-wrap">
              <p>
                <img className="w-100" src="https://lh3.googleusercontent.com/d/1KS6L3gLKMJeH7q4xj0IdbaA7ll2cr4VL" alt="candidature" />
              </p>
            </div>
          </div>
        </div>
        <div className="row my-4 justify-content-center">
          <div className="main_title_2">
            <span>
              <em />
            </span>
            <h2>Mots des étudiants</h2>
          </div>
          <div className="flip-card-wrapper">
            {
              STUDENTSBASE.map((student, index) => <Student key={index} student={student} />)
            }
          </div>
        </div>
        <div className="row my-4">
          <div className="main_title_2">
            <span>
              <em />
            </span>
            <h2>Partenaires</h2>
          </div>
          <div className="parteners">
            <div className="sliderStart d-block">
              <div className="slider">
                <div className="row">
                  <div className={`slider-wrapper slick-initialized slick-slider justify-content-center green-shadow ${suiv ? "next" : ""} ${prec ? "prev" : ""}`}>
                    <div className="row p-2">
                      {
                        parteners.map((img, index) => <Parteners key={index} src1={img.src1} src2={img.src2} />)
                      }
                    </div>
                  </div>
                </div>
                <div className={`row ${PartenersImgs.length > 4 ? "" : "d-none"}`}>
                  <div className="slider-controls">
                    <button type="button" className="slick-arrow slick-prev" onClick={() => prev(true)}>
                      prev
                    </button>
                    <div className="progress" style={{ display: "block", backgroundSize: `${partPosition}% 100%` }} />
                    <button type="button" className="slick-arrow slick-next" onClick={() => next(true)}>
                      next
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="row my-4">
          <div className="main_title_2">
            <span>
              <em />
            </span>
            <h2>Entreprises partenaires</h2>
          </div>
          <div className="parteners">
            <div className="sliderStart d-block">
              <div className="slider">
                <div className="row">
                  <div className="slider-wrapper slick-initialized slick-slider orange-shadow">
                    <div className="row p-2">
                      {
                        society.map((img, index) => <Parteners key={index} src1={img.src1} src2={img.src2} />)
                      }
                    </div>
                  </div>
                </div>
                <div className={`row ${SocietyImgs.length > 4 ? "" : "d-none"}`}>
                  <div className="slider-controls">
                    <button type="button" className="slick-arrow slick-prev" onClick={() => prev(false)}>
                      prev
                    </button>
                    <div className="progress" style={{ display: "block", backgroundSize: `${socPosition}% 100%` }} />
                    <button type="button" className="slick-arrow slick-next" onClick={() => next(false)}>
                      next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 justify-content-evenly">
          <div className="main_title_2 mt-4">
            <span>
              <em />
            </span>
            <h2>Réseaux sociaux</h2>
          </div>
          <div className="col-lg-4 col-sm-6 col-xs-12">
            <div className="d-block d-sm-none"><h4>Page Facebook</h4></div>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcodeloccol&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
              width={340}
              height={500}
              className="social-shadow w-100 tweet"
              style={{
                border: "none",
                overflow: "hidden"
              }}
              scrolling="no"
              frameBorder={0}
              allowFullScreen={true}
              title="Codeloccol"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            />
          </div>
          <div className="col-lg-4 col-sm-6 col-xs-12">
            <div className="d-block d-sm-none"><br /><h4>Page Twitter</h4></div>
            <iframe
              id="twitter-widget-2"
              scrolling="no"
              frameBorder={0}
              allowFullScreen={true}
              className="social-shadow w-100"
              style={{
                position: "static",
                visibility: "visible",
                width: 331,
                height: 450,
                display: "block",
                flexGrow: 1
              }}
              title="Twitter Timeline"
              src="https://syndication.twitter.com/srv/timeline-profile/screen-name/Codeloccol?dnt=false&embedId=twitter-widget-2&frame=false&hideBorder=false&hideFooter=false&hideHeader=false&hideScrollBar=false&lang=en&maxHeight=450px&origin=https%3A%2F%2Fdeveloper.twitter.com%2Fen%2Fdocs%2Ftwitter-for-websites&sessionId=e3719c617f6c19fc4f4ef09c08d6fa539bbc08df&showHeader=true&showReplies=false&transparent=false&widgetsVersion=1c23387b1f70c%3A1664388199485"
            />
          </div>
        </div>
      </div>
    </>

  )
}
export default HomeContent