import { FunctionComponent, useEffect, useState } from "react";
import { AdmissionService } from "../../../services/AdmissonServices";
const AuthUserPiscine: FunctionComponent = () => {
  const today: any = new Date();
  const start: any = new Date("February 6, 2023 08:00:00 GMT+1:00");
  const [counter, setCounter] = useState<number>(
    Math.floor((start - today) / 1000)
  );
  const [see, setSee] = useState<boolean>(false);
  const [passed, setPassed] = useState<boolean>(false);

  useEffect(() => {
    const admissionService = new AdmissionService();
    setSee(false);
    admissionService
      .findTest()
      .then((test) => {
        if (test.algo.isDone && test.logic.isDone) {
          setPassed(true);
        } else {
          setSee(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setPassed(false);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((seconds) => seconds - 1);
    }, 1000);

    if (counter >= 0) setSee(true);
    else {
      if (counter > -60 * 60 * 24 * 30) {
        if (passed) setSee(true);
      }
      if (counter < -60 * 60 * 24 * 30) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval);
  }, [counter, passed]);
  return (
    <>
      {!see && (
        <div className="est">
          <span className="loader"></span>
        </div>
      )}
      <section className={`meet ${!see && "d-none"}`}>
        <div className="container py-5 mx-0">
          <div className="row">
            <div className="main_title_2">
              <span>
                <em />
              </span>
              <h2>Piscine</h2>
            </div>
          </div>
          <div className="row mx-4 justify-content-evenly">
            <div className="col col-sm-12">
              {see && (
                <>
                  {counter >= 0 ? (
                    <h4 className="mt-4 text-justify">
                      La piscine qui était prevu pour le Mercredi 1er fevrier 2023 a été reportée pour le Lundi 06 Février 2023
                    </h4>
                  ) : (
                    <>
                      {passed ? (
                        <>
                          <div className="row mt-4">
                            <div className="col">
                              <div className="row">
                                <div className="col">
                                  <a
                                    href="https://www.piscine.codeloccol.org"
                                    target={"_blank"}
                                    className={"h3 mb-3"}
                                  >
                                    Lien pour acceder à la piscine
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <h4>
                            Nous sommes désolé cette partie est réservée
                            uniquement pour les personnes qui ont fait le test.
                            <br /> <br />
                            Nous te remercions pour l'interet que tu as à
                            accorder à notre école.
                            <br /> <br />
                            Merci et au revoir.
                          </h4>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AuthUserPiscine;
