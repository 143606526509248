import { FunctionComponent } from "react"
import Layout from "./layout"
import ErrorContent from "../components/404/404"

interface Props {
  counter : number
}

const ErrorPage: FunctionComponent<Props> = ({ counter }) => {
  document.title = "Codeloccol | 404";
  return <Layout content={<ErrorContent />} banner={<></>} loaderForm={false} counter={counter} />
}

export default ErrorPage