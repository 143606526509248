import {
  FunctionComponent,
  useState,
  ChangeEvent,
  FormEvent,
  useEffect,
} from "react";
import { AdmissionService } from "../../../../services/AdmissonServices";
import Quiz from "./quiz";
import { AllQuiz } from "../../../../models/test";

interface Props {
  timeConvert: (counter: number) => string;
  setStep: (step: number) => void;
  setSee: (see: boolean) => void;
  addMinutes: (date: Date, minutes: number) => Date;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>, str: string) => void;
}

const Logic: FunctionComponent<Props> = ({
  timeConvert,
  handleInputChange,
  setStep,
  setSee,
  addMinutes,
}) => {
  const [counter, setCounter] = useState<number>(0);
  const [sticky, setSticky] = useState({});
  const [sub, setSub] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(false);

  const logicQuiz = AllQuiz.filter((all: Quiz, index: number) => index < 10);
  const admissionService = new AdmissionService();

  useEffect(() => {
    setStart(false);
    admissionService
      .createTest("logic")
      .then((logic) => {
        const end: any = addMinutes(new Date(logic.quiz.logic.start), 15);
        const actuel: any = new Date();
        if (logic.quiz.logic.end) {
          setStep(logic.quiz.algo.end ? 0 : 2);
        } else {
          if (end > actuel) setSee(true);
          else saveLogic();
        }

        if (document.readyState === "complete") {
          if (end > actuel) setCounter((end - actuel) / 1000);
          setStart(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setSee(true);
      })
      .finally(() => setSee(true));
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((seconds) => seconds - 1);
    }, 1000);

    if (counter < 0) {
      if (start) saveLogic();
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [counter, start]);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 0
        ? setSticky({ position: "fixed !important", top: "-10" })
        : setSticky({});
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSub(true);
    const localLogicForm: QuestionReponse[] = JSON.parse(
      localStorage.getItem("logic") || JSON.stringify([])
    );
    const logicError = localLogicForm.filter((logic) => logic.reponse === "");
    if (logicError.length === 0) saveLogic();
  };

  const saveLogic = () => {
    setSee(false);
    const localLogicForm: QuestionReponse[] = JSON.parse(
      localStorage.getItem("logic") || JSON.stringify([])
    );
    admissionService
      .saveTest("logic", localLogicForm)
      .then((logic) => {
        if (!logic.quiz.logic.end) {
          setSee(true);
        } else {
          if (!logic.quiz.algo.end) {
            const conf = window.confirm(
              "En cliquant sur OK je déclare sur mon honneur que je fais le test moi-même"
            );
            if (conf) setStep(2);
            else setStep(0);
          } else {
            setStep(0);
          }
        }
        if (logic.quiz.logic.allResponses.length > 0)
          localStorage.removeItem("logic");
      })
      .catch((err) => {
        console.log(err);
        setSee(true);
      })
      .finally(() => setSee(true));
  };

  return (
    <>
      <div className="row justify-content-evenly">
        <div className="col-lg-8 mb-4">
          <div className="row">
            <div className="col">
              <h5>Test logique</h5>
            </div>
            <div className="col text-end">
              <h5> {timeConvert(counter)} </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="testscrool" style={sticky}>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {logicQuiz.map((lq, index) => (
            <Quiz
              handleInputChange={handleInputChange}
              query={lq}
              step={index + 1}
              key={index}
              str={"logic"}
              sub={sub}
            />
          ))}
          <button className="btn btn-outline-success mt-4" type="submit">
            Soumettre
          </button>
        </form>
      </div>
    </>
  );
};

export default Logic;
