import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AdmissionListPage from './pages/admissionList';
import AdmissionTestPage from './pages/admissionTest';
import AboutPage from './pages/about'
import AdmissionPage from './pages/admission';
import FaqPage from './pages/faq';
import ContactPage from './pages/contact';
import HomePage from './pages/home';
import ErrorPage from './pages/errorPage';
import LoginPage from './pages/login';
import UserInfoPage from './pages/userInfo';
import UserTestPage from './pages/userTest';
import UserMeetPage from './pages/userMeet';
import UserPiscinePage from './pages/userPiscine';
import AdmissionGirlPage from './pages/admissionGirl';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { isAuthentificated } from './services/auth';
import ProtectedRoute from './components/outils/protectedRoute';

function App() {
  const today: any = new Date();
  const deathLine: any = new Date('January 7, 2023 23:59:59 GMT+1:00');
  const deathLine2: any = new Date('January 21, 2023 23:59:59 GMT+1:00');
  const [counter, setCounter] = useState<number>((deathLine - today) / 1000);
  
  const [auth, setAuth] = useState<boolean>(isAuthentificated().isAuth);
  useEffect(() => {
    if (counter < -60 * 60 * 24 && deathLine2 > today) setCounter(((deathLine2 - today) / 1000))
    const interval = setInterval(() => {
      setCounter(seconds => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);
  return (
    <BrowserRouter>
      <div className='body'>
        <Routes>
          <Route path="/" element={<HomePage counter={counter < 0 ? 0 : counter} />} />
          {
            counter > 0 && <Route path="/apply" element={<AdmissionPage counter={counter < 0 ? 0 : counter} />} />
          }


          <Route path="/applyGirl" element={<AdmissionGirlPage counter={counter < 0 ? 0 : counter} />} />
          <Route path="/user/sign_in" element={<LoginPage counter={counter < 0 ? 0 : counter} />} />
          {
            isAuthentificated().profil === "admin" && (
              <>
                <Route path="/admission/list" element={
                  <ProtectedRoute isAuthenticated={auth}>
                    <AdmissionListPage />
                  </ProtectedRoute>
                } />
                <Route path="/admission/test" element={
                  <ProtectedRoute isAuthenticated={auth}>
                    <AdmissionTestPage />
                  </ProtectedRoute>
                } />
              </>
              
            )
          }
          <Route path="/user/info" element={
            <ProtectedRoute isAuthenticated={auth}>
              <UserInfoPage />
            </ProtectedRoute>
          } />
          <Route path="/user/test" element={
            <ProtectedRoute isAuthenticated={auth}>
              <UserTestPage />
            </ProtectedRoute>
          } />
          <Route path="/user/meet" element={
            <ProtectedRoute isAuthenticated={auth}>
              <UserMeetPage />
            </ProtectedRoute>
          } />
          <Route path="/user/piscine" element={
            <ProtectedRoute isAuthenticated={auth}>
              <UserPiscinePage />
            </ProtectedRoute>
          } />
          <Route path="/about" element={<AboutPage counter={counter < 0 ? 0 : counter} />} />
          <Route path="/contact" element={<ContactPage counter={counter < 0 ? 0 : counter} />} />
          <Route path="/faq" element={<FaqPage counter={counter < 0 ? 0 : counter} />} />
          <Route path="/*" element={<ErrorPage counter={counter < 0 ? 0 : counter} />} />
        </Routes>
      </div>
    </BrowserRouter>
  )

}

export default App;