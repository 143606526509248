import {FunctionComponent, useState} from "react"
import Layout from "./layout"
import LoginContent from "../components/login/loginContent";

interface Props {
  counter : number
}

const LoginPage: FunctionComponent<Props> = ({ counter }) => {
  const [loaderForm, setLoaderForm] = useState<boolean>(false);
  return <Layout content={<LoginContent setLoaderForm= {setLoaderForm} />} banner={<></>} loaderForm= {loaderForm} counter={counter} />
}

export default LoginPage