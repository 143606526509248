import React, { FunctionComponent, ReactElement } from 'react'
import { Navigate, useLocation } from "react-router-dom"

interface Props {
  children: ReactElement
  isAuthenticated: boolean
}

const ProtectedRoute: FunctionComponent<Props> = ({ children, isAuthenticated }) => {
  let location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/user/sign_in" state={{ from: location }} replace />
  }
  return children;
};

export default ProtectedRoute;