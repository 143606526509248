import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
const SubMenuAdmin: FunctionComponent = () => {
  const title = document.URL.split("/")[4]
  return (
    <ul>
      <li className={`list ${title === "list" && "active"}`}>
        <Link to="/admission/list">
          <span className="icon">
            <FontAwesomeIcon icon={faUsers} />
          </span>
          <span className="title">Admissions</span>
        </Link>
      </li>
      <li className={`list ${title === "test" && "active"}`}>
        <Link to="/admission/test">
          <span className="icon">
            <FontAwesomeIcon icon={faUserGraduate} />
          </span>
          <span className="title">Test</span>
        </Link>
      </li>
    </ul>
  );
};
export default SubMenuAdmin;