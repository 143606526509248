import { FunctionComponent, useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import Wizard from "./wizard/wizardGirl";
import ActionWizard from "./wizard/actionWizard";
// import AdmissionModal from "./admissionModal";
// import AdmissionContractModal from "./admissionContractModal";
import { AdmissionService } from "../../services/AdmissonServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "../admission/admission.css";
import "./admissionGirl.css";

interface Props {
  setLoaderForm: (bool: boolean) => void
}

const AdmissionContent: FunctionComponent<Props> = ({ setLoaderForm }) => {

  const [step, setStep] = useState<number>(1);
  const [start, setStart] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [contract, setContract] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [code, setCode] = useState<number>()
  if(localStorage.getItem('admissionForm') === null) localStorage.setItem('admissionForm', JSON.stringify({}));
  const localForm : AdmissionApi = JSON.parse(localStorage.getItem('admissionForm') ||  JSON.stringify({}));
  
  const [admissionForm, setAdmissionForm] = useState<Admission>({
    name: { value: localForm.name, isValid: true },
    mail: { value: localForm.mail, isValid: true },
    country: { value: localForm.country, isValid: true },
    city: { value: localForm.city, isValid: true },
    mobile: { value: localForm.mobile, isValid: true },
    age: { value: localForm.age, isValid: true },
    gender: { value: localForm.gender, isValid: true },
    isHandicap: { value: localForm.isHandicap, isValid: true },
    handicap: { value: localForm.handicap, isValid: true },
    coding: { value: localForm.coding, isValid: true },
    isLearnCoding: { value: localForm.isLearnCoding, isValid: true },
    adventure: { value: localForm.adventure, isValid: true },
    whyDoNotLearnCoding: { value: localForm.whyDoNotLearnCoding, isValid: true },
    learn: { value: localForm.learn, isValid: true },
    likeToAchieve: { value: localForm.likeToAchieve, isValid: true },
    techEnvironment: { value: localForm.techEnvironment, isValid: true },
    validate: { value: "", isValid: true }
  });

  useEffect(() => {
    localStorage.setItem('admissionForm', JSON.stringify(objectValue(admissionForm)));
  }, [admissionForm]);

  const noDuplicate = (str : string) => {
    let string = str, noLetters = str, duplicate:string[] = [];
    const strings = str.split('');
    for(let i in strings) {
      if(+i > 0) {
        if(strings[+i-1] == strings[+i]) {
          duplicate = [...duplicate, strings[+i]];
        }
      }
      if (duplicate.length > 1) {
        noLetters = string.substring(0, +i) +  string.substring(+i+1, string.length);
      }
    }
    return noLetters;
  }
  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: Field = { [fieldName]: { value: fieldValue, isValid: true} };
    setAdmissionForm({ ...admissionForm, ...newField });
  };

  function wordCount(str:string) {
    return str && str.trim() !== "" ? str.trim().split(/['-_.|\s]+/).length : 0;
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName: string = e.target.name;
    const fieldValue: string = (e.target.name === "mobile" || e.target.name === "age") ? e.target.value.replace(/\D/g, '') : e.target.value;
    const newField: Field = { [fieldName]: { value: fieldValue, isValid: true } };
    setAdmissionForm({ ...admissionForm, ...newField });
  };

  const nextStep = () => {
    if ((admissionForm.isHandicap.value === "false" && step === 2) || (admissionForm.isLearnCoding.value === "false" && step === 5) || (admissionForm.isLearnCoding.value === "true" && step === 6)) setStep(step + 2);
    else setStep(step + 1);
  };

  const prevStep = () => {
    setValidate(false)
    if ((admissionForm.isHandicap.value === "false" && step === 4) || (admissionForm.isLearnCoding.value === "false" && step === 7) || (admissionForm.isLearnCoding.value === "true" && step === 8)) setStep(step - 2);
    else setStep(step - 1);
  };
  const barWidth = {
    width: `${step * 9.10}%`,
  };

  const objectValue = (object: any) => {
    let newObj: any = {}
    for (let obj in object) {
      if (obj !== "validate") {
        newObj[obj] = object[obj].value
      }
    }
    return newObj
  }
  
  const newObject = (object: any) => {
    let newObj: any = {}
    for (let obj in object) {
      if (obj !== "validate") {
        if(obj === "mobile") {
          const tel = `${code}${object[obj].value}`;
          object[obj].value = +tel;
        }
        if (obj === "isHandicap" || obj === "isLearnCoding") newObj[obj] = object[obj].value === "true" ? true : false
        else newObj[obj] = object[obj].value;
      }
    }
    return newObj
  }


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    handlePrevent(e);
    setSubmit(false);
    const admission = newObject(admissionForm);
    const admissionService = new AdmissionService();
    setLoaderForm(true);
    admissionService.createAdmission(admission).then((response) => {
      if (response.type) {
        setStep(121);
        localStorage.setItem('admissionForm', JSON.stringify(objectValue({})));
      } else {
        const copy = admissionForm;
        for (let obj in response.exite) {
          if (response.exite[obj].mail === admission.mail) copy.mail = { error: `Ce mail ${admission.mail} existe deja dans notre liste d'admission`, value: "" };
          if (response.exite[obj].mobile == admission.mobile) copy.mobile = { error: `Ce numero ${admission.mobile} existe deja dans notre liste d'admission`, value: "" };
        }
        setAdmissionForm(copy);
        setStep(1);
      }
      setLoaderForm(false);
    });
  };
  const handlePrevent = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  return (
    <>
      <div id="form_container" className="girl">
        <div className="row admission w-100 mx-0">
          {/*<div className="col-lg-6 px-0">
            <div id="left_form">
              <div>
                <figure className="fig">
                  <Link to="/">
                    <img src="https://drive.google.com/uc?export=view&id=1kt19GxQPxqZmgnextRI5pzm3BykjVhl1" alt="Logo ANSI" width="350" height="250" />
                    <img src="https://drive.google.com/uc?export=view&id=1C6SC-Dlh5IXPscgG6MUdvEFP7nNtMadV" alt="Logo Niger" width="350" height="150" />
                    <img src="https://drive.google.com/uc?export=view&id=1BKvZNCkPaU618_ddXMUa7apUS9zZniZJ" alt="Logo Codeloccol" width="175" height="125" />
                    <img src="https://drive.google.com/uc?export=view&id=1A8OTDM-Oblwv9pj-N0Uoouuzuk76TWCR" alt="Logo A.D.U." width="175" height="75" />
                  </Link>
                </figure>
                <div className="p-4 text-center info">
                  <h2>
                    Bienvenue<span>Sur le formulaire qui vous permet de candidater à Connected African Girl Coding Camp. Une Initiative de l'Agence National pour la Société de l'Information</span>
                    <span>En partenariat avec la Commission Economique pour l'Afrique, Codeloccol et Ilimi - The African Development University</span>
                  </h2>
                  <p>
                    Tu es une jeune fille agée entre 12 et 25 ans ? Tu t'intétresse au numérique et à la programmation Informatique en particulier ? Tu es au bon endroit.
                  </p>
                  <p>
                    Remplis le formulaire ci-après, vérifies tes emails et sois prête pour l'aventure.                  
                  </p>
                </div>
                <Link
                  to="/"
                  className="btn_1 rounded yellow purchase"
                  onClick={()=> {
                    window.close();
                  }}
                >
                  Retourner à l'accueil
                </Link>
                {
                  !start && <Link
                    to="#!"
                    className="btn_1 rounded mobile_btn yellow"
                    onClick={() => {setStart(!start); window.location.href = "#bg"}}
                  >
                    Commence ici !
                  </Link>
                }
              </div>
              <Link
                to="#!"
                id="more_info"
                data-toggle="modal"
                data-target="#more-info"
                onClick={() => setModal(true)}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Link>
              </div>
            </div>*/}
          <div id="bg" >
            <div id="wizard_container">
              <div id={`${step !== 121 && "top-wizard"}`} className={`${step === 121 && "d-none"}`}>
                <div
                  id="progressbar"
                  className="girl ui-progressbar ui-widget ui-widget-content ui-corner-all"
                  role="progressbar"
                >
                  <div
                    className="girl ui-progressbar-value ui-widget-header ui-corner-left"
                    style={barWidth}
                  />
                </div>
                <span id="location">
                  {/* {step} sur 11 */}
                </span>
              </div>
              <div id="wizard_content">
                <form
                  id="wrapped"
                  onSubmit={(e) => { submit ? handleSubmit(e) : handlePrevent(e); }}
                  className="fl-form fl-style-1 wizard-form girl"
                >
                  <input id="website" name="website" type="text" value="" />
                  <div id="middle-wizard">
                    <Wizard
                      step={step}
                      admissionForm={admissionForm}
                      setAdmissionForm={setAdmissionForm}
                      handleInputChange={handleInputChange}
                      handleTextareaChange={handleTextareaChange}
                      wordCount={wordCount}
                      validate={validate}
                      setValidate={setValidate}
                      nextStep={nextStep}
                      setSubmit={setSubmit}
                      contract={contract}
                      setContract={setContract}
                      setLoaderForm={setLoaderForm}
                      setCode={setCode}
                    />
                  </div>
                  {
                    step !== 121 && (
                      <ActionWizard
                        prevStep={prevStep}
                        step={step}
                        submit={submit}
                        setValidate={setValidate}
                      />
                    )
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AdmissionModal modal={modal} setModal={setModal} />
      {
        step === 11 && <AdmissionContractModal contract={contract} setContract={setContract} />
      } */}
    </>
  );
};
export default AdmissionContent;
