import { apiUrl } from '../constant'
const token = sessionStorage.getItem('__SECURE') || "";
export class AdmissionService {
  public async getAllAdmissions(): Promise<any> {
    const response = await fetch(`${apiUrl}/api/admissions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `dsdf446ihg ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  }

  public async getAllQuizs(): Promise<any> {
    const response = await fetch(`${apiUrl}/api/quiz/result`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `dsdf446ihg ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  }

  public async findAdmission(): Promise<any> {
    const response = await fetch(`${apiUrl}/api/admission`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `dsdf446ihg ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  }

  public async createTest(test : string): Promise<any> {
    const response = await fetch(`${apiUrl}/api/quiz/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `dsdf446ihg ${token}`
      },
      body: JSON.stringify({ test: test }),
    });
    const responseJson = await response.json();
    return responseJson;
  }

  public async saveTest(test: string, quiz : {question: string, reponse : string}[]): Promise<any> {
    const response = await fetch(`${apiUrl}/api/quiz/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `dsdf446ihg ${token}`
      },
      body: JSON.stringify({ test : test, quiz: quiz }),
    });
    const responseJson = await response.json();
    return responseJson;
  }

  public async findTest(): Promise<any> {
    const response = await fetch(`${apiUrl}/api/quiz/find`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `dsdf446ihg ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  }


  public async existeAdmission(data: any): Promise<any> {
    const response = await fetch(`${apiUrl}/api/admission/existe`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ admission: data }),
    });
    return await response.json();
  }

  public async createAdmission(data: any): Promise<any> {
    const response = await fetch(`${apiUrl}/api/admission`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ admission: data }),
    });
    return await response.json();
  }

  public async deleteAdmission(admissionId: number): Promise<any> {
    const response = await fetch(`${apiUrl}/api/admission/${admissionId}`, { method: "DELETE" });
    return await response.json();
  }

  public async editAdmission(data: any): Promise<any> {
    const response = await fetch(`${apiUrl}/api/admission`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ admission: data }),
    });
    return await response.json();
  }

  public async sendMail(data: any): Promise<any> {
    const response = await fetch(`${apiUrl}/api/contact`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ contact: data }),
    });
    return await response.json();
  }
}