import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import "./about.css";

interface Props {
  newTab: (tab: string) => void;
}

const AboutContent: FunctionComponent<Props> = ({ newTab }) => {
  return (
    <>
      <div className="main_title_2">
        <span>
          <em />
        </span>
        <h2>Fondateurs</h2>
        <p>
          Codeloccol est le fruit d'un partenariat innovant entre trois
          organisations
        </p>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <Link
            to=""
            className="box_topic"
            onClick={() => {
              newTab("https://ansi.ne/");
            }}
          >
            <span>
              <img src="https://lh3.googleusercontent.com/d/1kt19GxQPxqZmgnextRI5pzm3BykjVhl1" width={70} height={70} alt="Logo ANSI" />
            </span>
            <h3>ANSI</h3>
            <p>Agence National pour la Société de l'Information</p>
            <p />
          </Link>
        </div>
        <div className="col-sm-4">
          <Link
            to=""
            className="box_topic"
            onClick={() => {
              newTab("https://www.cipmen.org/");
            }}
          >
            <span>
              <img src="https://lh3.googleusercontent.com/d/1oUsmaR-h6QmreYzdwGpjNIGTyrYTkex-" width={70} height={70} alt="Logo CIPMEN" />
            </span>
            <h3>CIPMEN</h3>
            <p>Centre Incubateur des Petites et Moyennes Entreprises</p>
            <p />
          </Link>
        </div>
        <div className="col-sm-4">
          <Link
            to=""
            className="box_topic"
            onClick={() => {
              newTab("https://ilimi.edu.ne/");
            }}
          >
            <span>
              <img src="https://lh3.googleusercontent.com/d/1HrRWX70cwWPoAeGllU-XW30GnFz2M-bs" width={70} height={70} alt="Logo ADU" />
            </span>
            <h3>ADU</h3>
            <p>ILIMI <br></br> The African Development University</p>
            <p/>
            <p/>
          </Link>
        </div>
      </div>
      <div className="main_title_2">
        <span>
          <em />
        </span>
        <h2>Pédagogie</h2>
        <p>L'approche Codeloccol se base sur trois concepts</p>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <a className="box_topic" href="#0">
            <span>
              <img src="https://lh3.googleusercontent.com/d/1heqxpvbOtG8vC4W7Aq57pUvzinjGT26v" width={70} height={70} alt="self learn" />
            </span>
            <h3>Le self learning</h3>
            <p />
          </a>
        </div>
        <div className="col-sm-4">
          <a className="box_topic" href="#0">
            <span>
              <img src="https://lh3.googleusercontent.com/d/1hVR7b9CEh9Z7GuDSfGxJh-VQDfrK7Fk5" width={70} height={70} alt="peer to peer" />
            </span>
            <h3>Le peer Learning</h3>
            <p />
          </a>
        </div>
        <div className="col-sm-4">
          <a className="box_topic" href="#0">
            <span>
              <img src="https://lh3.googleusercontent.com/d/1rkiWNW9RGOiZQAJzq5024vhIg807hE8s" width={70} height={70} alt="mentoring" />
            </span>
            <h3>Coaching &amp; Mentoring</h3>
            <p />
          </a>
        </div>
      </div>
      <div className="main_title_2">
        <span>
          <em />
        </span>
        <h2>Roadmap</h2>
        <p>
          CodeLoccol vous accompagne à être un développeur Full Stack avec une Roadmap réfléchie et adaptée pour tous. <br/>
        </p>
        <img className="w-100 img-fluid road" src="https://lh3.googleusercontent.com/d/13Xyjqgb8Bmfe3rU7R0dJ-jocvXNR480N" alt="RoadMap" />
      </div>
    </>
  );
};
export default AboutContent;
