import { apiUrl } from '../constant';
import jwt_decode from 'jwt-decode';
export class ConnexionService {
	public async assiri(data: { login: string, password: string }): Promise<any> {
		const response = await fetch(`${apiUrl}/api/login`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ connexion: data }),
		});
		return await response.json();
	}
}