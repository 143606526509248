import {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect,
  FormEvent,
} from "react";
import Quiz from "./quiz";
import { AllQuiz } from "../../../../models/test";
import { AdmissionService } from "../../../../services/AdmissonServices";

interface Props {
  timeConvert: (counter: number) => string;
  setStep: (step: number) => void;
  setSee: (loaderForm: boolean) => void;
  addMinutes: (date: Date, minutes: number) => Date;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>, str: string) => void;
}

const Algo: FunctionComponent<Props> = ({
  timeConvert,
  handleInputChange,
  setStep,
  setSee,
  addMinutes,
}) => {
  const [counter, setCounter] = useState<number>(0);
  const [sticky, setSticky] = useState({});
  const [sub, setSub] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(false);

  const admissionService = new AdmissionService();
  const algoQuiz = AllQuiz.filter((all: Quiz, index: number) => index >= 10);

  useEffect(() => {
    setStart(false);
    admissionService
      .createTest("algo")
      .then((algo) => {
        const end: any = addMinutes(new Date(algo.quiz.algo.start), 15);
        const actuel: any = new Date();
        if (algo.quiz.algo.end) {
          setStep(algo.quiz.logic.end ? 0 : 1);
        } else {
          if (end > actuel) setSee(true);
          else saveAlgo();
        }

        if (document.readyState === "complete") {
          if (end > actuel) setCounter((end - actuel) / 1000);
          setStart(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setSee(true);
      })
      .finally(() => setSee(true));
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((seconds) => seconds - 1);
    }, 1000);

    if (counter < 0) {
      if (start) saveAlgo();
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [counter, start]);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 0
        ? setSticky({ position: "fixed !important", top: "-10" })
        : setSticky({});
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSub(true);
    const localAlgoForm: QuestionReponse[] = JSON.parse(
      localStorage.getItem("algo") || JSON.stringify([])
    );
    const algoError = localAlgoForm.filter((algo) => algo.reponse === "");
    if (algoError.length === 0) saveAlgo();
  };

  const saveAlgo = () => {
    const localAlgoForm: QuestionReponse[] = JSON.parse(
      localStorage.getItem("algo") || JSON.stringify([])
    );
    setSee(false);
    admissionService
      .saveTest("algo", localAlgoForm)
      .then((algo) => {
        if (!algo.quiz.algo.end) {
          setSee(true);
          alert("erreur");
        } else {
          if (!algo.quiz.logic.end) {
            const conf = window.confirm(
              "En cliquant sur OK je déclare sur mon honneur que je fais le test moi-même"
            );
            if (conf) setStep(1);
            else setStep(0);
          } else {
            setStep(0);
          }
        }
        if (algo.quiz.algo.allResponses.length > 0)
          localStorage.removeItem("algo");
      })
      .catch((err) => {
        console.log(err);
        setSee(true);
      })
      .finally(() => setSee(true));
  };

  return (
    <>
      <div className={`row justify-content-evenly`}>
        <div className="col-lg-8 mb-4">
          <div className="row">
            <div className="col">
              <h5>Test Algorithmique</h5>
            </div>
            <div className="col text-end">
              <h5> {timeConvert(counter)} </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="testscrool" style={sticky}>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {algoQuiz.map((al, index) => (
            <>
              <Quiz
                handleInputChange={handleInputChange}
                query={al}
                step={index}
                key={index}
                str={"algo"}
                sub={sub}
              />
            </>
          ))}
          <button className="btn btn-outline-success mt-4">Soumettre</button>
        </form>
      </div>
    </>
  );
};

export default Algo;
