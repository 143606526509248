import { FunctionComponent, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { AdmissionService } from "../../../services/AdmissonServices";
const AuthUserMeet: FunctionComponent = () => {
  const today: any = new Date();
  const start: any = new Date("February 3, 2023 10:00:00 GMT+1:00");
  const [counter, setCounter] = useState<number>(
    Math.floor((start - today) / 1000)
  );
  const [see, setSee] = useState<boolean>(false);
  const [passed, setPassed] = useState<boolean>(false);

  useEffect(() => {
    const admissionService = new AdmissionService();
    setSee(false);
    admissionService
      .findTest()
      .then((test) => {
        if (test.algo.isDone && test.logic.isDone) {
          setPassed(true);
        } else {
          setSee(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setPassed(false);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((seconds) => seconds - 1);
    }, 1000);

    if (counter >= 0) setSee(true);
    else {
      if (counter > -60 * 60) {
        if (passed) setSee(true);
      }
      if (counter < -60 * 60) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval);
  }, [counter, passed]);
  return (
    <>
      {!see && (
        <div className="est">
          <span className="loader"></span>
        </div>
      )}
      <section className={`meet ${!see && "d-none"}`}>
        <div className="container py-5 mx-0">
          <div className="row">
            <div className="main_title_2">
              <span>
                <em />
              </span>
              <h2>Entretien</h2>
            </div>
          </div>
          <div className="row mx-4 justify-content-evenly">
            <div className="col col-sm-12">
              {see && (
                <>
                  {counter >= 0 ? (
                    <h4 className="mt-4 text-justify">
                      Le meet qui était prevu pour le Mercredi 1er fevrier 2023 a été reporté pour le Vendredi 03 février 2023
                    </h4>
                  ) : (
                    <>
                      {passed ? (
                        <>
                          <div className="row mt-4">
                            <div className="col">
                              <div className="row">
                                <div className="col">
                                  <a
                                    href="https://meet.google.com/fff-oggb-hus"
                                    target={"_blank"}
                                    className={"h3 mb-3"}
                                  >
                                    Lien pour acceder à la piscine
                                  </a>
                                  <p style={{ marginTop: 100 }}>
                                    Scanner l'image pour acceder au meet
                                  </p>

                                  {isMobile && (
                                    <div className="col mt-1">
                                      <img
                                        src="https://drive.google.com/uc?export=view&id=1HIXPf_6ZWMM2CpAIW_v2BrAndH9eSqpj"
                                        alt="avatar"
                                        className="img-fluid"
                                        width={200}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {!isMobile && (
                                <div className="col">
                                  <img
                                    src="https://drive.google.com/uc?export=view&id=1HIXPf_6ZWMM2CpAIW_v2BrAndH9eSqpj"
                                    alt="avatar"
                                    className="img-fluid"
                                    width={200}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <h4>
                            Nous sommes désolé cette partie est réservée
                            uniquement pour les personnes qui ont fait le test.
                            <br /> <br />
                            Nous te remercions pour l'interet que tu as à
                            accorder à notre école.
                            <br /> <br />
                            Merci et au revoir.
                          </h4>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AuthUserMeet;
