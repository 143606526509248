import { FunctionComponent, ReactNode } from "react";
import "./authContent.css";

interface Props {
  content : ReactNode;
}

const AuthContent: FunctionComponent<Props> = ({ content }) => {
  const title = document.URL.split("/")[4]
  return <div className={`contenu ${title === "test" && "noaction"}`}> { content } </div>
}

export default AuthContent;