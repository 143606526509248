import { FunctionComponent } from "react"
import HomeContent from "../components/accueil/homeContent"
import HomeBanner from "../components/banner/home"
import Layout from "./layout"

interface Props {
  counter : number
}

const HomePage : FunctionComponent<Props> = ({counter})=>{
  return <Layout content={<HomeContent counter={counter} />} banner={<HomeBanner />} loaderForm= {false} counter={counter}/>
}
export default HomePage