import { FunctionComponent, useEffect, useState } from "react";
import { AdmissionService } from "../../../services/AdmissonServices";
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { ExportToCsv } from 'export-to-csv';
import { Button, Modal, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFileArrowDown} from "@fortawesome/free-solid-svg-icons";

interface Props {
  setLoaderForm: (loaderForm: boolean) => void;
}

const columns: MRT_ColumnDef[] = [
  {
    accessorKey: 'index',
    header: 'ID',
    size: 40,
  },
  {
    accessorKey: 'name',
    header: 'Nom & Prénom',
    size: 500,
  },
  {
    accessorKey: 'mail',
    header: 'e-mail',
    size: 100,
  },
  {
    accessorKey: 'mobile',
    header: 'WhatsApp',
    size: 50,
  },
  {
    accessorKey: 'city',
    header: 'Ville',
    size: 100,
  },
  {
    accessorKey: 'country',
    header: 'Pays',
    size: 100,
  }
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const AuthAdminTest: FunctionComponent<Props> = ({ setLoaderForm }) => {
  const admissionService = new AdmissionService()
  const [candidates, setCandidates] = useState([])
  const [modal, setModal] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<AdmissionApi>();


  useEffect(() => {
    setLoaderForm(true);
    admissionService.getAllQuizs().then(res => {
      Object.keys(res).map(r => {
        res[r].name = `${res[r].gender === "Homme" ? "M." : "Melle"} ${res[r].name}`;
        res[r].mobile = `+${res[r].mobile}`;
        res[r].algo = `${res[r].algo}/10`;
        res[r].logic = `${res[r].logic}/10`;
        res[r].note = `${res[r].note}/20`;
      })
      setCandidates(res);
      setLoaderForm(false);
    }).catch(err => { console.log(err); })
  }, []);

  const columns: MRT_ColumnDef[] = [
    {
      accessorKey: 'name',
      header: 'Nom & Prénom',
      size: 120,
    },
    {
      accessorKey: 'mail',
      header: 'E-mail',
      size: 120,
    },
    {
      accessorKey: 'mobile',
      header: 'WhatsApp',
      size: 90,
    },
    {
      accessorKey: 'city',
      header: 'Ville',
      size: 90
    },
    {
      accessorKey: 'logic',
      header: 'Logique',
      size: 40
    },
    {
      accessorKey: 'algo',
      header: 'Algorithme',
      size: 40
    },
    {
      accessorKey: 'note',
      header: 'Note',
      size: 40
    }
  ];
  
  const handleExportRows = (rows: any[]) => {
    csvExporter.generateCsv(rows.map(row => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(candidates);
  };

  return (
    <Row className="mt-4 pt-4">
      <Col className="main_title_2 mt-4">
        <span>
          <em />
        </span>
        <h2>Résutat du test</h2>
      </Col>
      <Row className="px-2">
        <MaterialReactTable
          columns={columns}
          data={candidates}
          enableRowSelection={false}
          enableColumnActions={false}
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => (
            <Row className="d-flex align-items-center w-50">
              <Button
                className="col"
                onClick={handleExportData}
                variant="contained"
              >
                <FontAwesomeIcon className="after" icon={faFileArrowDown} /> {" "}
                Tableau
              </Button>
              <Button
                className="col"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                variant="contained"
              >
                <FontAwesomeIcon className="after" icon={faFileArrowDown} /> {" "}
                Les lignes
              </Button>
              <Button
                className="col"
                disabled={table.getRowModel().rows.length === 0}
                onClick={() => handleExportRows(table.getRowModel().rows)}
                variant="contained"
              >
                <FontAwesomeIcon className="after" icon={faFileArrowDown} /> {" "}
                La page
              </Button>
              <Button
                className="col"
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                variant="contained"
              >
                <FontAwesomeIcon className="after" icon={faFileArrowDown} /> {" "}
                Les lignes selectionner
              </Button>
            </Row>
          )}
          enableFullScreenToggle={false}
          enableRowNumbers
          rowNumberMode="original"
          localization={MRT_Localization_FR}
        />
      </Row>
    </Row>
  )
}

export default AuthAdminTest