import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { CountryService } from "../../../services/CountryService"
import { Link } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent, useState, useEffect, FormEvent, ChangeEvent } from "react";
import Wizard from "./wizardGirl";
import ActionWizard from "./actionWizard";
// import AdmissionModal from "./admissionModal";
// import AdmissionContractModal from "./admissionContractModal";
import "../../admission/admission.css";
import "./../admissionGirl.css";
import { AdmissionService } from "../../../services/AdmissonServices";


interface Props {
    admissionForm: Admission                                                      
    nextStep: () => void
   
  }

const WelcomeWizard: FunctionComponent<Props> =  ({ admissionForm, nextStep }) => {

    const [start, setStart] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);

    
    return (
        <div id="wizard_container">
              <div>
                <figure className="fig">
                  <Link to="/">
                    <img src="https://drive.google.com/uc?export=view&id=1kt19GxQPxqZmgnextRI5pzm3BykjVhl1" alt="Logo ANSI" width="350" height="250" />
                    <img src="https://drive.google.com/uc?export=view&id=1C6SC-Dlh5IXPscgG6MUdvEFP7nNtMadV" alt="Logo Niger" width="350" height="150" />
                    <img src="https://drive.google.com/uc?export=view&id=1BKvZNCkPaU618_ddXMUa7apUS9zZniZJ" alt="Logo Codeloccol" width="175" height="125" />
                    <img src="https://drive.google.com/uc?export=view&id=1A8OTDM-Oblwv9pj-N0Uoouuzuk76TWCR" alt="Logo A.D.U." width="175" height="75" />
                  </Link>
                </figure>
                <div className="p-4 text-center info">
                  <h2>
                    Bienvenue<span>Sur le formulaire qui vous permet de candidater à Connected African Girl Coding Camp. Une Initiative de l'Agence National pour la Société de l'Information</span>
                    <span>En partenariat avec la Commission Economique pour l'Afrique, Codeloccol et Ilimi - The African Development University</span>
                  </h2>
                  <p>
                    Tu es une jeune fille agée entre 12 et 25 ans ? Tu t'intétresse au numérique et à la programmation Informatique en particulier ? Tu es au bon endroit.
                  </p>
                  <p>
                    Remplis le formulaire ci-après, vérifies tes emails et sois prête pour l'aventure.                  
                  </p>
                </div>
                <Link
                  to="/"
                  className="btn_1 rounded yellow purchase"
                  onClick={()=> {
                    window.close();
                  }}
                >
                  Retourner à l'accueil
                </Link>
                {
                  !start && <Link
                    to="#!"
                    className="btn_1 rounded mobile_btn yellow"
                    onClick={() => {setStart(!start); window.location.href = "#bg"}}
                  >
                    Commence ici !
                  </Link>
                }
              </div>
              <Link
                to="#!"
                id="more_info"
                data-toggle="modal"
                data-target="#more-info"
                onClick={() => setModal(true)}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Link>
              </div>
    );
        
    
    
            
        
};

export default WelcomeWizard;