const url = "https://lh3.googleusercontent.com/d/";
const STUDENTSBASE: Students[] = [{
    picture : url+"1PNQUD6PaWjz3qYjRDW8stQDedJWoOxXY",
    name : "Haoua Enikoye",
    age : 0,
    profil : "Dev Front-End",
    says : "D'étudiante en sociologie à Codeuse, c'est un défi relevé ! A Codeloccol, j'ai particulièrement aimé le fait de me retrouver avec un groupe qui partage la même passion. J'ai eu la chance de rencontrer des personnes extraordinaires. C'est vrai ça n'a pas été facile, je vous demande donc d'être courageux et de ne plus avoir peur de vos ambition et vous deviendrez un très bon codeur !"
  }, {
    picture : url+"1ADYUvKzWiYvl86LGw506fYWLlJSGmYgJ",
    name : "Omar Hamadou",
    age : 0,
    profil : "Dev Full Stack",
    says : "Codeloccol m'a permis d'apprendre à coder. Aujourd'hui je rêve de devenir un grand développeur et participer à apporter des solutions à certains problèmes et difficultés dont nous faisons face dans notre société à travers le numérique."
  }, {
    picture : url+"1sUpwB25Es9L_t0iTSVfQO-qa2knkTYlX",
    name : "Latifa Wahabi",
    age : 0,
    profil : "Dev Front-End",
    says : "Je suis nutritionniste de formation. En venant à Codeloccol, je n'avais aucune notion du Coding. J'avais peur d'échouer malgré mon envie d'apprendre. Grâce au peer-to-peer learning, j'ai pris confiance et appris en une année ce que beaucoup n'arrive pas à apprendre en trois ans. Codeloccol, c'est bien plus que le coding, c'est le savoir vivre en communauté, apprendre des autres et aux autres."
  }, {
    picture : url+"1DKVLb2nQlF-jnOvnBassjusSZGqI4Uje",
    name : "Mahamadou Nouriddine",
    age : 0,
    profil : "Dev Full Stack",
    says : "Je suis venu à Codeloccol avec un pari que j'estimais presque irréalisable : Devenir développeur full stack en une année. Aujourd'hui je suis plus que satisfait du résultat. A présent tout est possible pour moi. J'ambitionne de continuer dans la voie du coding et apporter des solutions à travers l'IA."
  }
]

export default STUDENTSBASE
