import {FunctionComponent, useState} from "react"
import Layout from "./layout"
import AdmissionContent from "../components/admissionGirl/admissionGirl"

interface Props {
  counter : number
}

const AdmissionPage: FunctionComponent<Props> = ({ counter }) => {
  const [loaderForm, setLoaderForm] = useState<boolean>(false);
  document.title = "Codeloccol | Apply";
  return <Layout content={<AdmissionContent setLoaderForm= {setLoaderForm} />} banner={<></>} loaderForm= {loaderForm} counter={counter} />
}

export default AdmissionPage