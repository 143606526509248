import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import Test from "./test";
import Logic from "./logic";
import Algo from "./algo";

interface Props {
  step: number;
  setStep: (step: number) => void;
  setSee: (see: boolean) => void;
  see: boolean;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>, str: string) => void;
  seeBtn: boolean;
}

const Wizard: FunctionComponent<Props> = ({
  step,
  setStep,
  setSee,
  see,
  handleInputChange,
  seeBtn,
}) => {
  const timeConvert = (time: number) => {
    if (time > 0) {
      let hh: string, mm: string, ss: string, dd: string;
      let days: number = Math.floor(time / (60 * 60 * 24));
      let hours: number = Math.floor((time - days * 60 * 60 * 24) / (60 * 60));
      let minutes: number = Math.floor(
        (time - (days * 60 * 60 * 24 + hours * 60 * 60)) / 60
      );
      let seconds: number = Math.floor(
        time - (days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60)
      );
      days > 1 ? (dd = `0${days}`) : (dd = `${days}`);
      hours < 10 ? (hh = `0${hours}`) : (hh = `${hours}`);
      minutes < 10 ? (mm = `0${minutes}`) : (mm = `${minutes}`);
      seconds < 10 ? (ss = `0${seconds}`) : (ss = `${seconds}`);
      return `${hh}:${mm}:${ss}`;
    } else return "00:00:00";
  };

  const addMinutes = (date: Date, minutes: number): Date => {
    const dateCopy = new Date(date);
    dateCopy.setMinutes(date.getMinutes() + minutes);
    return dateCopy;
  };

  useEffect(() => {
    if (see) setSee(false);
  }, [step]);

  switch (step) {
    case 0:
      return <Test setStep={setStep} setSee={setSee} seeBtn={seeBtn} />;
    case 1:
      return (
        <Logic
          timeConvert={timeConvert}
          handleInputChange={handleInputChange}
          setStep={setStep}
          setSee={setSee}
          addMinutes={addMinutes}
        />
      );
    case 2:
      return (
        <Algo
          timeConvert={timeConvert}
          handleInputChange={handleInputChange}
          setStep={setStep}
          setSee={setSee}
          addMinutes={addMinutes}
        />
      );
    default:
      return <Test setStep={setStep} setSee={setSee} seeBtn={seeBtn} />;
  }
};
export default Wizard;
