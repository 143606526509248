export const AllQuiz: Quiz[] = [
  {
    question: "Si « Niamey » est encrypté de la manière suivante  « Pkcoga » comment peut on coder Agadez ?",
    allResponse: [
      "Dhdgfa",
      "Cicfgb",
      "Cichfd",
      "Dhdfgb"
    ]
  },
  {
    question: "Quelle nombre arrive après dans la série suivante ? 10, 17, 26, 37, ?",
    allResponse: [
      "50",
      "56",
      "47",
      "44"
    ]
  },
  {
    question: "E représente un nombre. Par exemple si E est 5, EE est égal à 55 et 4E est égale à 45. Si 1E x E = 9E, quel nombre peut remplacer E ? :",
    allResponse: [
      "0",
      "1",
      "5",
      "6"
    ]
  },
  {
    question: "C et D représente chacun des chiffres différents. Par exemple, si C = 2, CCC = 222. Si C + CC = D4 quel nombre peut remplacer C :",
    allResponse: [
      "2",
      "4",
      "7",
      "9"
    ]
  },
  {
    question: "Quelle est la lettre suivante dans la séquence : A - H - A - E - A - ?",
    allResponse: [
      "A",
      "B",
      "C",
      "D"
    ]
  },
  {
    question: "https://drive.google.com/uc?export=view&id=19T3Sq4kUaLGERMHu6t0cqYzFNaweq1Qr<br />Quel est le nombre suivant ?",
    allResponse: [
      "25",
      "36",
      "35",
      "37"
    ]
  },
  {
    question: `Les nombres premiers (les nombres divisibles par seulement 1 et eux même) suivent la séquence suivantes :  
                1, 2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, ….
                Vu les informations au dessus, considérer la séquences:
                5, 8, 12, 18, 24, …
                Quel nombre vient à la suite de cette séquence ?`,
    allResponse: [
      "30",
      "31",
      "29",
      "32"
    ]
  },
  {
    question: `Un enseignant enregistre la taille de 5 étudiants dans une classe. En comparant leurs tailles, l’enseignant trouve ça : <br />
                1. Kalcie est plus grande que Ridy mais plus courte que Alga <br />
                2. Dino est plus grand que Michou mais plus court que Kalcie <br />
                Qui est le plus grand ?  
    `,
    allResponse: [
      "Kalcie",
      "Dino",
      "Ridy",
      "Alga"
    ]
  },
  {
    question: `Un professeur d’EPS fait l’inventaire de son magasin. Il trouve des balles de handball, de volleyball, de football et de basketball. Il trouve les quantités suivantes 4, 5, 6, 8 et 10 (une quantité différente pour chaque type de balles). Il fait le constat suivant : <br />
                1. Il y’a moins de balles de volleyball que de balles de football <br />
                2. Il y’a 6 balles de basketball de plus que les balles de handball <br />
                Combien de balles de football y’a t-il ? `,
    allResponse: [
      "4",
      "6",
      "8",
      "10"
    ]
  },
  {
    question: `Quatre filles Assamaou, Zeinabou, Cherifa et Djamila sont appariée avec quatre garçons Adam, Zakari, Chetima et Daouda. <br /> 
                <strong>*</strong><span class="me-1"></span> Adam est avec Zeinabou <br />
                <strong>*</strong><span class="me-1"></span> Daouda n’est pas avec Assamaou <br />
                <strong>*</strong><span class="me-1"></span> Seulement un parmi les couples a les deux noms commençant par la même lettre <br />
                Si nous pouvons déterminer tout les pairs avec certitude à partir de ces informations, quelle fille est avec Chetima ? `,
    allResponse: [
      "Assamaou",
      "Zeinabou",
      "Cherifa",
      "Djamila"
    ]
  },
  {
    question: "Un « algorithme » est un ensemble d’instructions que doivent être suivis dans un ordre donnée. Cette section vous montrera différents petits « algorithmes », écris en français. Vous n’avez besoin d’aucune expérience en programmation pour comprendre ces questions; essayez simplement d'utiliser votre bon sens et suivez attentivement les étapes.  ",
    allResponse: []
  },
  {
    question: `Imaginez un algorithme qui prend un mot (mot d’origine) et exécute les étapes suivantes : <br /> 
              1. Prend la première lettre de ce mot et la copie sur une nouvelle ligne. <br /> 
              2. Copie la lettre suivante du mot d’origine et la place au début de la nouvelle ligne.  <br /> 
              3. Répète l’étape 2 jusqu’à ce qu’il n’y ait plus de lettre restante dans le mot d’origine.  <br />  
              Que fait ce algorithme ?  `,
    allResponse: [
      "Il copie les mots",
      "Il renverse les mots",
      "Il mélange les mots dans un ordre inconnu ",
      "Ça dépend du mot"
    ]
  },
  {
    question: `Imaginez un algorithme qui prend une liste de nombres et exécute les étapes suivantes : <br />  
              1. Trouve le plus grand nombre dans la liste originale et le déplace dans une nouvelle liste. <br />   
              2. Trouve le plus grand nombre parmi les nombres restant dans le liste originale et le déplace à la fin de la nouvelle liste. <br />  
              3. Répète l’étape 2 jusqu’à ce qu’il ne reste plus aucun nombre dans la liste originale. <br />  
              Que fait ce algorithme ?`,
    allResponse: [
      "Il trouve le nombre le plus petit dans une liste de nombres",
      "Il trouve le nombre le plus grand dans une liste de nombres",
      "Il ordonne la liste de nombres du plus petit au plus grand.",
      "Il ordonne la liste de nombres du plus grand au plus petit."
    ]
  },
  {
    question: `Nous écrivons un algorithme pour compter combien de fois la lettre « u » apparait dans un mot. Il y’a une ligne manquante. Choisissez l’option qui fera en sorte que le algorithme fonctionne.  
              1. Initier un compteur à 0 <br />  
              2. Regarder la première lettre du mot. Si la lettre est « u », augmenter le compteur de 1.  <br />  
              3. Regarder la lettre suivante du mot. Si la lettre est « u », augmenter le compteur de 1. <br />    
              4. >>>>>>> La ligne manquante <<<<<<<<< <br />  
              Quel peut être la ligne manquante ? `,
    allResponse: [
      "Répéter l’étape 3 jusqu’à ce que vous ayez regardé l’ensemble des lettres dans le mot ",
      "Regarder la lettre suivante du mot. Si la lettre est « u », augmenter le compteur de 1.",
      "Si la lettre suivante n’est pas « u », augmenter le compteur de 0.",
      "Si la prochaine lettre n’est « u », remettre le compteur à 0."
    ]
  },
  {
    question: `Imaginer un algorithme qui prend deux listes de nombres et exécute les étapes suivants : <br />   
                Prend chaque nombre de la liste 1 et vérifie si il est dans la liste 2.  <br />  
                1. Si oui, copie le nombre dans une nouvelle page, puis va au nombre suivant de la liste 1 <br />  
                2. Sinon, il va au nombre suivant de la liste 1<br />  
                Supposons que nous donnons au algorithme les deux listes : Liste 1 : [5,2,8,4,1] and List 2 : [3,7,2,5,4]. Quelle sera la sortie sur la nouvelle page ?`,
    allResponse: [
      "[2,5,4]",
      "[1,2,3,4,5,7,8]",
      "[5,2]",
      "[5,2,4]"
    ]
  },
  {
    question: `Imaginer un algorithme. Qui prend une liste de nom et exécute les actions suivantes :  
                1. Prend le premier mot et le copie dans une nouvelle page <br />  
                2. Prend le mot prochain et vérifies si le mot est dans la nouvelle page <br />  
                <span class="ms-1">A</span>. si oui, supprime le mot de la page originale  <br />  
                <span class="ms-1">B</span>. Si non, copie le mot dans un nouvelle page <br />  
                3. Répète l’étape 2 jusqu’au traitement de tout les mots de la liste. <br />   
                Que fait ce algorithme ?`,
    allResponse: [
      "Trouve les mots non dupliqués sur la page originale",
      "Trouve les mots dupliqués sur la page originale",
      "Supprime toutes les copies après la première occurence des mots dupliqués sur la page originale.",
      "Sans effet"
    ]
  },
  {
    question: `Nous essayons d’écrire un algorithme qui prend tous les fichiers dans un ordinateur et nous dis le nom et l’emplacement des doublons (tous les fichiers du même nom mais dans un dossier différents). Notre algorithme actuel est comme suit : <br />  
                1. Créer deux listes, la liste de vérification et la liste des duplicata <br />  
                2. Prendre un fichier de l’ordinateur. Vérifier s’il y’a un fichier du même nom dans la liste de vérification. <br />  
                <span class="ms-1">A.</span> S’il y’en a pas, copier le nom du fichier et son emplacement dans la liste de vérification <br />  
                <span class="ms-1">B.</span> S’il y’en a, copier le nom et les deux emplacement dans la liste duplicata. <br />  
                3. Répéter l’étape 2 jusqu’à la vérification complète de l’ensemble des fichiers se trouvant dans l’ordinateur. <br />  
                Laquelle de ces affirmation exprime un problème de notre algorithme actuel :
    `,
    allResponse: [
      "S’il y’a plus de deux fichiers avec le même nom, la liste des duplicata contiendra elle même des informations dupliquées.",
      "S’il y’a plus de deux fichiers du même nom ce algorithme ne trouvera pas tout les cas de duplicata",
      "S’il y’a des duplicata d’un fichier ce algorithme n’enregistrera pas l’emplacement du premier fichier ayant le même nom.",
      "Si le tout premier fichier que nous vérifions a un duplicata, ce algorithme ne vas le trouver."
    ]
  },
  {
    question: `Nous essayons de faire un algorithme qui prend n’importe quel mot et vérifies si le mot est « Code ». Il est comme suit :  <br />  
                1. Vérifier la première lettre du mot et vérifies si c’est un « c » <br />  
                2. Si oui, prendre la seconde lettre et vérifier si c’est un « o ». <br />  
                3. Si oui prendre la troisième lettre et vérifier si c’est un « d » <br />  
                4. Si oui prendre la quatrième lettre et vérifier si c’est un « e » <br />  
                5. Si toute ces étapes passent, envoyer le message « succès ! » <br />  
                Il y’a un bug (dysfonctionnement) dans ce algorithme : Il va dans l’erreur envoyer le message « succès » pour certains mots qui ne sont pas code. <br />   
                Lequel des mots suivant passerai notre test avec succès :`,
    allResponse: [
      "Ccode",
      "Kode",
      "Codage",
      "Codeur"
    ]
  },
  {
    question: ` Nous écrivons un algorithme qui prend un mot et vérifie combien de lettre il contient. Il est comme suit : <br />   
                1. Initier un compteur à 0 <br />  
                2. Commence avant le début du mot and avancer d’un pas vers la droite <br />  
                <span class="ms-1">A.</span> Si tu rencontre une lettre, augmente le compteur de 1. <br />  
                <span class="ms-1">B.</span Si tu rencontre autre chose qu’une lettre, afficher le compteur et terminer le algorithme. <br />  
                3. Répéter l’étape 2 jusqu’à la fin du algorithme. <br />  
                Supposons que nous donnons à ce algorithme la phrase « Réalise-le ! ». Quelle sera la réponse :  `,
    allResponse: [
      "4",
      "6",
      "7",
      "8"
    ]
  },
  {
    question: `Nous essayons d’écrire un algorithme qui vérifies si un mot donné est un palindrome (un mot se lie pareil de gauche à droite tout comme de droite à gauche). Notre algorithme actuel est : <br />  
    1) Prendre la première lettre et la dernière lettre du mot <br />  
    <span class="ms-1">A.</span> S’ils sont identiques, continuer <br />  
    <span class="ms-1">B.</span> S’ils sont différents, quitter le algorithme. <br />  
    2) Prendre la second lettre et l’avant dernière lettre du mot <br />  
    <span class="ms-1">A.</span> S’ils sont identiques, continuer <br />  
    <span class="ms-1">b.</span> S’ils sont différents, quitter le algorithme. <br />  
    3) Répéter l’étape 2 jusqu’à ce que vous ayez traité toute les lettres du mot. <br />  
    4) Si le algorithme est toujours en cours, vous avez trouvé un palindrome ! <br />  
    Certains palindromes ont un nombre pairs de lettres (ex. Elle) et d’autres ont un nombre impair de lettres (ex. Ressasser). Le algorithme actuel fonctionne-t-il pour :  `,
    allResponse: [
      "Ni pour les pairs ni pour les impairs",
      "Pour les impairs mais pas les pairs ",
      "Pour les pairs mais pas les impairs",
      "Pour les pairs et les impairs"
    ]
  },
  {
    question: ` Considerez le algorithme suivant : <br />  
    1. Initialiser le compteur à 0 <br />  
    2. Prendre le premier nombre de la liste 1 et comparez le au premier nombre de la liste 2 <br />  
    <span class="ms-1">A.</span> S’il sont les même, ajouter ce nombre au compteur <br />  
    <span class="ms-1">b.</span> S’il sont different, additionner les nombres ensembles et soustraire le résultat du compteur.  <br />  
    3. Répéter l’étape 2 jusqu’à ce que vous ayez traité chaque nombre de la liste 1.  <br />  
    4. Afficher le résultat du compteur. <br />  
    Quelle sera le résultat final du compteur si l’on donne au algorithme les deux listes suivants. Liste 1 : [5,1,-3,6] Liste 2 :  [5,2,7,6,-8,1] `,
    allResponse: [
      "-3",
      "-2",
      "0",
      "4",
      "9"
    ]
  }


]