import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faUserGraduate, faVideoCamera, faPersonSwimming } from "@fortawesome/free-solid-svg-icons";

const AuthBottom = () => {
  const [value, setValue] = React.useState(0);
  const title = document.URL.split("/")[4]
  return (
    <Box sx={{position: 'fixed', bottom: 0, left:0, right:0, borderTop: "1px solid #179349", marginTop:10}}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <Link to={"/user/info"} className={`${title === "info" && "Mui-selected"}`}>
          <BottomNavigationAction label="Info" icon={<FontAwesomeIcon icon={faInfo} />}/>
        </Link>
        <Link to={"/user/test"} className={`${title === "test" && "Mui-selected"}`}>
          <BottomNavigationAction label="Test" icon={<FontAwesomeIcon icon={faUserGraduate} />}/>
        </Link>
        <Link to={"/user/meet"}  className={`${title === "meet" && "Mui-selected"}`}>
          <BottomNavigationAction label="Meet" icon={<FontAwesomeIcon icon={faVideoCamera} />}/>
        </Link>
        <Link to={"/user/piscine"}  className={`${title === "piscine" && "Mui-selected"}`}>
          <BottomNavigationAction label="Piscine" sx={{}} icon={<FontAwesomeIcon icon={faPersonSwimming} />}/>
        </Link>
      </BottomNavigation>
    </Box>
  );
}

export default AuthBottom