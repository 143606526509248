import { FunctionComponent, ReactNode, useState } from "react";
import "./authNavbar.css"

interface Props {
  navbar : ReactNode;
}

const AuthNavbar: FunctionComponent<Props> = ({ navbar }) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const styleOver = () => {
    setMouseOver(true);
  }
  const styleOut = () => {
    setMouseOver(false);}
  return <div className="navigation"  style={{width: mouseOver ? 200 : 70}} onMouseOver={styleOver} onMouseOut={styleOut}> { navbar } </div>
}
export default AuthNavbar