import {FunctionComponent} from "react"
import Layout from "./layout"
import AboutBanner from "../components/banner/about"
import AboutContent from "../components/about/about"

interface Props {
  counter : number
}

const AboutPage: FunctionComponent<Props> = ({ counter }) => {
  const openTab = (url: string) => {
    window.open(url);
  };

  return <Layout content={<AboutContent newTab={openTab}/>} banner={<AboutBanner />} loaderForm= {false} counter={counter} />
}

export default AboutPage