import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import "./authHeader.css"
const AuthHeader: FunctionComponent = () => {
  return (
    <div className="header">
      <div className="logo">
        <img
          src="https://drive.google.com/uc?export=view&id=1tr2OrSke7D-GQCw5AuZV1qdNYnF2s8vL"
          alt=""
        />
      </div>
      <div className="search">
        <div id="myBtn" className="search-box">
          <span className="icon">
          </span>
          <span className="title">Search field</span>
        </div>
      </div>
      <div className="user">
        <label htmlFor="btn" className="button">
          <img src="https://drive.google.com/uc?export=view&id=1J9A2xJsHcZkiV6_qgORQA8h4TlSF2-71" alt="avatar" />
          <span className="icon">
          </span>
        </label>
        <input type="checkbox" className="checked" id="btn" />
        <ul>
          <li>
            <Link to="/user/sign_in" onClick={() => {sessionStorage.removeItem('__SECURE')}}>
              <span className="icon">
              </span>
              <span className="title">Déconnexion</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AuthHeader