import { FunctionComponent, ReactNode } from "react";
import "./authSubMenu.css";
interface Props {
  submenu : ReactNode
}
const AuthSubMenu: FunctionComponent<Props> = ( { submenu }) => {
  return (
    <div className="submenu">
      { submenu }
    </div>
  )
}
export default AuthSubMenu