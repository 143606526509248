import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faUserGraduate, faVideoCamera, faPersonSwimming } from "@fortawesome/free-solid-svg-icons";
const SubMenuUser: FunctionComponent = () => {
  const title = document.URL.split("/")[4]
  return (
    <ul>
      <li className={`list ${title === "info" && "active"}`}>
        <Link to="/user/info">
          <span className="icon">
            <FontAwesomeIcon icon={faInfo} />
          </span>
          <span className="title">Info</span>
        </Link>
      </li>
      <li className={`list ${title === "test" && "active"}`}>
        <Link to="/user/test">
          <span className="icon">
            <FontAwesomeIcon icon={faUserGraduate} />
          </span>
          <span className="title">Test</span>
        </Link>
      </li>
      <li className={`list ${title === "meet" && "active"}`}>
        <Link to="/user/meet">
          <span className="icon">
            <FontAwesomeIcon icon={faVideoCamera} />
          </span>
          <span className="title">Meet</span>
        </Link>
      </li>
      <li className={`list ${title === "piscine" && "active"}`}>
        <Link to="/user/piscine">
          <span className="icon">
            <FontAwesomeIcon icon={faPersonSwimming} />
          </span>
          <span className="title">Piscine</span>
        </Link>
      </li>
    </ul>
  );
};
export default SubMenuUser;