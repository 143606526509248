import { FunctionComponent, useEffect, useState } from "react";
import { AdmissionService } from "../../../services/AdmissonServices";
import "bootstrap/dist/css/bootstrap.css";
import "./authAdminAdmission.css";
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { ExportToCsv } from 'export-to-csv';
import { Button, Modal, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFileArrowDown} from "@fortawesome/free-solid-svg-icons";

interface Props {
  setLoaderForm: (loaderForm: boolean) => void;
}

const columns: MRT_ColumnDef[] = [
  {
    accessorKey: 'index',
    header: 'ID',
    size: 40,
  },
  {
    accessorKey: 'name',
    header: 'Nom & Prénom',
    size: 500,
  },
  {
    accessorKey: 'mail',
    header: 'e-mail',
    size: 100,
  },
  {
    accessorKey: 'mobile',
    header: 'WhatsApp',
    size: 50,
  },
  {
    accessorKey: 'city',
    header: 'Ville',
    size: 100,
  },
  {
    accessorKey: 'country',
    header: 'Pays',
    size: 100,
  }
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const AuthAdminAdmission: FunctionComponent<Props> = ({ setLoaderForm }) => {
  const admissionService = new AdmissionService()
  const [candidates, setCandidates] = useState([])
  const [modal, setModal] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<AdmissionApi>();


  useEffect(() => {
    setLoaderForm(true);
    admissionService.getAllAdmissions().then(res => {
      Object.keys(res).map(r => {
        res[r].name = `${res[r].gender === "Homme" ? "M." : "Melle"} ${res[r].name}`;
        res[r].mobile = `+${res[r].mobile}`;
        res[r].age = `${res[r].age} ans`;
      })
      setCandidates(res);
      setLoaderForm(false);
    }).catch(err => { console.log(err); })
  }, []);

  const columns: MRT_ColumnDef[] = [
    {
      accessorKey: 'name',
      header: 'Nom & Prénom',
      size: 120,
    },
    {
      accessorKey: 'mail',
      header: 'e-mail',
      size: 120,
    },
    {
      accessorKey: 'mobile',
      header: 'WhatsApp',
      size: 90,
    },
    {
      accessorKey: 'city',
      header: 'Ville',
      size: 90
    },
    {
      accessorKey: 'country',
      header: 'Pays',
      size: 90
    }
  ];

  const allInfoUser = (user: any) => {

    setModal(!modal);
    return setModalInfo(user);
  }

  const returnDate = (sub: any) => {
    const subSplit = sub.split("-");
    const subSlip1 = subSplit[2].split("T")
    return `${subSlip1[0]}/${subSplit[1]}/${subSplit[0]}`
  }

  const handleExportRows = (rows: any[]) => {
    csvExporter.generateCsv(rows.map(row => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(candidates);
  };

  return (
    <Row className="mt-4 pt-4">
      <Col className="main_title_2 mt-4">
        <span>
          <em />
        </span>
        <h2>Listes des Admissions</h2>
      </Col>
      <Row className="px-2">
        <MaterialReactTable
          columns={columns}
          data={candidates}
          enableRowSelection={false}
          enableColumnActions={false}
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => (
            <Row className="d-flex align-items-center w-50">
              <Button
                className="col"
                onClick={handleExportData}
                variant="contained"
              >
                <FontAwesomeIcon className="after" icon={faFileArrowDown} /> {" "}
                Tableau
              </Button>
              <Button
                className="col"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                variant="contained"
              >
                <FontAwesomeIcon className="after" icon={faFileArrowDown} /> {" "}
                Les lignes
              </Button>
              <Button
                className="col"
                disabled={table.getRowModel().rows.length === 0}
                onClick={() => handleExportRows(table.getRowModel().rows)}
                variant="contained"
              >
                <FontAwesomeIcon className="after" icon={faFileArrowDown} /> {" "}
                La page
              </Button>
              <Button
                className="col"
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                variant="contained"
              >
                <FontAwesomeIcon className="after" icon={faFileArrowDown} /> {" "}
                Les lignes selectionner
              </Button>
            </Row>
          )}
          enableFullScreenToggle={false}
          enableRowNumbers
          rowNumberMode="original"
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <Row sx={{ display: 'flex', gap: '1rem' }}>
              <Button size="sm" onClick={() => { allInfoUser(row.original) }}> Details </Button>
            </Row>
          )}
          localization={MRT_Localization_FR}
        />
      </Row>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modal}
        onHide={() => setModal(!modal)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="">Information du candidat</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {
            modalInfo &&
            <>
              <Row>
                <h3>Identité</h3>
                <Row>
                  <Col lg={2} className="bold">Nom &amp; Prénom </Col>
                  <Col lg={10} className="text-capitalize">{modalInfo.name}</Col>
                </Row>
                <Row>
                  <Col lg={2} className="bold">Email</Col>
                  <Col lg={3} className="text-capitalize">{modalInfo.mail}</Col>
                  <Col lg={2}></Col>
                  <Col lg={2} className="bold">WhatsApp</Col>
                  <Col lg={3} className="text-capitalize">{modalInfo.mobile}</Col>
                </Row>
                <Row>
                  <Col lg={2} className="bold">Pays</Col>
                  <Col lg={3} className="text-capitalize">{modalInfo.country}</Col>
                  <Col lg={2}></Col>
                  <Col lg={2} className="bold">Ville</Col>
                  <Col lg={3} className="text-capitalize">{modalInfo.city}</Col>
                </Row>
                <Row>
                  <Col lg={2} className="bold" >Age</Col>
                  <Col lg={3}>{modalInfo.age}</Col>
                  <Col lg={2}></Col>
                  <Col lg={2} className="bold">Soumis le</Col>
                  <Col lg={3} className="text-capitalize">{returnDate(modalInfo.subscribe)}</Col>
                </Row>
              </Row>
              <Row className="mt-4">
                <h3>Situation d'handicap</h3>
                <Row>
                  {/* <Col lg={3} className="bold">Handicap </Col>
                  <Col lg={2} className="text-capitalize">{modalInfo.isHandicap ? <>Oui</> : <>Non</>}</Col> */}
                  <Col lg={3} className="bold">Nature </Col>
                  <Col lg={9}>{modalInfo.isHandicap ? <>{modalInfo.handicap}</> : <>Pas d'handicap</>}</Col>
                </Row>
              </Row>
              <Row className="mt-4">
                <h3>Pourquoi nous ?</h3>
                <Row>
                  <Col lg={3} className="bold">Motivation de faite codeloccol</Col>
                  <Col lg={9} className="text-justify">{modalInfo.coding}</Col>
                </Row>
              </Row>
              <Row className="mt-4">
                <h3>Coder</h3>
                <Row>
                  {/* <Col lg={2} className="bold">Essayer de coder</Col>
                  <Col lg={1} className="text-capitalize">{modalInfo.isLearnCoding ? <>Oui</> : <>Non</>}</Col> */}
                  <Col lg={3} className="bold">{modalInfo.isLearnCoding ? <>Aventure</> : <>No experience</>}</Col>
                  <Col lg={9} className="text-justify">{modalInfo.isLearnCoding ? <>{modalInfo.adventure}</> : <>{modalInfo.whyDoNotLearnCoding}</>}</Col>
                </Row>
              </Row>
              <Row className="mt-4">
                <h3>La meilleure façon d'apprendre est de pratiquer</h3>
                <Row>
                  <Col lg={3} className="bold">Point de vue</Col>
                  <Col lg={9} className="text-justify">{modalInfo.learn}</Col>
                </Row>
              </Row>
              <Row className="mt-4">
                <h3>Projet</h3>
                <Row>
                  <Col lg={3} className="bold">Resumé son projet</Col>
                  <Col lg={9} className="text-justify">{modalInfo.likeToAchieve}</Col>
                </Row>
              </Row>
              <Row className="mt-4">
                <h3>Environnement Tech</h3>
                <Row>
                  <Col lg={3} className="bold">Environnement de son pays</Col>
                  <Col lg={9} className="text-justify">{modalInfo.techEnvironment}</Col>
                </Row>
              </Row>
            </>
          }
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            className=" btn_1"
            variant="success"
            onClick={() => setModal(!modal)}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  )
}

export default AuthAdminAdmission