import { FunctionComponent } from "react"
import "./authFooter.css"
const AuthFooter: FunctionComponent = () => {
  return (
    <div className="pied">
      <p>
        © <strong>{new Date().getFullYear()} Admission </strong> une application de <strong>Codeloccol</strong>
      </p>
    </div>
  )
}

export default AuthFooter