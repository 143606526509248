import { FunctionComponent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faRobot, faClock } from "@fortawesome/free-solid-svg-icons";
import { AdmissionService } from "../../../../services/AdmissonServices";

interface Props {
  setStep: (step: number) => void;
  setSee: (see: boolean) => void;
  seeBtn: boolean;
}

const Test: FunctionComponent<Props> = ({ setStep, setSee, seeBtn }) => {
  const [algoBtn, setAlgoBtn] = useState<boolean>(false);
  const [logicBtn, setLogicBtn] = useState<boolean>(false);
  const [seeNote, setSeeNote] = useState<boolean>(false);
  const [noteAlgo, setNoteAlgo] = useState<number>(0);
  const [noteLogic, setNoteLogic] = useState<number>(0);
  const [awaitFecth, setAwaitFecth] = useState<boolean>(false);

  useEffect(() => {
    const admissionService = new AdmissionService();
    admissionService
      .findTest()
      .then((test) => {
        if (!test.algo.isDone && seeBtn) setAlgoBtn(true);
        else {
          setAlgoBtn(false);
          setNoteAlgo(test.algo.note);
          localStorage.removeItem("algo");
        }
        if (!test.logic.isDone && seeBtn) setLogicBtn(true);
        else {
          setLogicBtn(false);
          setNoteLogic(test.logic.note);
          localStorage.removeItem("logic");
        }
        if (test.logic.isDone && test.algo.isDone) setSeeNote(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setAwaitFecth(true));
  }, []);

  useEffect(() => {
    if (!seeBtn) {
      setAlgoBtn(false);
      setLogicBtn(false);
    }

    if(awaitFecth) setSee(true);
  }, [seeBtn, awaitFecth]);

  return (
    <div className="row mx-4 justify-content-evenly">
      <div className="col-lg-3">
        <div className="card">
          <div className="card-body text-center ">
            <div className="title mb-2">
              <FontAwesomeIcon className="quote" icon={faBrain} />
              <h5>Logique</h5>
            </div>
            <div className="clock">
              <span>
                <FontAwesomeIcon className="quote" icon={faClock} />
                {logicBtn ? <>15 min</> : <>Test terminé</>}
              </span>
            </div>
            {logicBtn ? (
              <div className="d-grid gap-2 mt-3">
                <button
                  className="btn btn-outline-success"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Commencer
                </button>
              </div>
            ) : (
              <>{seeNote && <>Note: {noteLogic}/10</>}</>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="card">
          <div className="card-body text-center">
            <div className="title mb-2">
              <FontAwesomeIcon className="quote" icon={faRobot} />
              <h5>Algorithmique</h5>
            </div>
            <div className="clock">
              <span>
                <FontAwesomeIcon className="quote" icon={faClock} />
                {algoBtn ? <>15 min</> : <>Test terminé</>}
              </span>
            </div>
            {algoBtn ? (
              <div className="d-grid gap-2 mt-3">
                <button
                  className="btn btn-outline-success"
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Commencer
                </button>
              </div>
            ) : (
              <>{seeNote && <>Note: {noteAlgo}/10</>}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;
