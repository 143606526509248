import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
const NavBarAdmin: FunctionComponent = () => {
  return (
    <ul>
      <li className="list active">
        <Link to="#!">
          <span className="icon">
            <FontAwesomeIcon icon={faHouse} />
          </span>
          <span className="title">Dashboard</span>
        </Link>
      </li>

      <li className="logout list">
        <Link to="/user/sign_in" onClick={() => { sessionStorage.removeItem('__SECURE') }}>
          <span className="icon">
            <FontAwesomeIcon icon={faRightFromBracket} />
          </span>
          <span className="title">Log out</span>
        </Link>
      </li>
    </ul>
  );
};
export default NavBarAdmin;